import React from "react";
import { Box, Typography, } from '@mui/material';

import ItemGrid from "components/courses/list/cardsGrid";
import TildeColor from 'assets/images/dashboard/tilde_salmon.png';

function MentoriaSection() {  

	return (		
    <Box sx={{ height: { xs: 1200, md: 950 }, width: 1, display: 'flex', justifyContent: 'center', bgcolor: 'yoguic.gris.background' }}>
      <Box sx={{ width: 1, maxWidth: 1440, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ height: '95%', width: '95%', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 3}}>
          <Box component="img" src={TildeColor} alt="tilde_color" sx={{ width: {xs: 40, md:70} }} />
          <Typography variant="h4" component="h4" sx={{ color: 'yoguic.negro.primario', fontWeight:'bold', textAlign: { xs: 'center', md: 'left'} }}>
            Programas guiados
          </Typography>
          <Typography variant="body1" component="div" sx={{ color: 'yoguic.negro.primario', textAlign: { xs: 'center', md: 'left'} }}>
            Los programas guiados te ofrecen un <b>acompañamiento</b> mucho más <b>personalizado</b> por parte de la docente de cada curso. Con <b>sesiones online en directo en grupos muy reducidos</b> de trabajo que te permitirán afianzar tus conocimientos a medida.
          </Typography>
          <ItemGrid category={"mentoria"} />
        </Box>
      </Box> 
    </Box>  
	);
}

export default MentoriaSection;
