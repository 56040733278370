import React from "react";
import { Box, Typography } from '@mui/material';

import TildeColor from 'assets/images/dashboard/tilde_salmon.png';

function JuntxsTexto() {
  const margin = { xs: 0, md: 4 }; 

	return (
    <Box sx={{ height: 1, width: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 2}}>
      <Box component="img" src={TildeColor} alt="tilde_color" sx={{ width: {xs: 40, md:70} }} />
      <Typography variant="h5" component="h3" sx={{fontWeight: 'bold', ml: margin}}>
        Más fácil juntxs
      </Typography>
      <Typography variant="subtitle1" component="div" sx={{ml: margin}}>
        La comunidad nos aporta respaldo, recursos, opciones y perspectivas diferentes, enriqueciendo nuestra carrera profesional.
      </Typography>
      <Typography variant="subtitle1" component="div" sx={{ml: margin}}>
        Si has elegido el yoga como un camino profesional, sabrás que puede resultar tremendamente gratificante, pero también exigente y solitario.
      </Typography>      
      <Typography variant="subtitle1" component="div" sx={{fontWeight: 'bold', ml: margin}}>
        No tenemos trucos, secretos y guías paso a paso, esta web no va de esto. 
      </Typography>          
      <Typography variant="subtitle1" component="div" sx={{ml: margin}}>
        Esta web va de proporcionarte un espacio en el que crecer profesionalmente junto a personas con tus mismos intereses.  
      </Typography>   
      <Typography variant="subtitle1" component="div" sx={{fontWeight: 'bold', ml: margin}}>
        Para que solamente tengas que enfocarte en el camino que quieres construir.  
      </Typography>   
    </Box>
	);
}

export default JuntxsTexto;