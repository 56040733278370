import React from "react";
import { Box } from '@mui/material';

function CondicionesVenta() {
  return (
    <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', bgcolor: 'yoguic.gris.background' }}>
      <Box sx={{ width: 1, maxWidth: 1440, display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }} >
        <Box sx={{ width: '90%' }}>
          <div>
            <h1>Condiciones de venta</h1>  
            <p>
            Fecha de actualización de las condiciones de venta: 16/10/2022<br/>
            En caso de duda contactar a: hola@yoguic.com
            </p>
            <p>
            Datos de titularidad de la web: Rocío Rodríguez Murillo, NIF: 53288584-E, dirección postal: C/Passavant 2,  Folgueroles 08519 (Barcelona), correo electrónico: hola@yoguic.com.
            </p>
            <p>
            A través de estas condiciones legales de contratación, ponemos a disposición del consumidor la información pre-contractual a la que se refiere el artículo 6 de la Directiva 2011/83.
            </p>
            <p>
            <b>Primera, identificación: </b>Los bienes que se venden a través de la web yoguic.com son infoproductos consistentes en cursos online.
            </p>
            <p>
            <b>Segunda, productos y precios: </b>En la página web pueden adquirirse cursos, formación. <br/>
            Todos los productos aparecen definidos en una ficha y muestran el precio de los mismos. Los precios de los servicios o productos mostrados en nuestra web se indican en euros e incluyen impuestos (cuando estos sean aplicables) a no ser que se indique lo contrario. Los precios e impuestos varían en función del país y región del cliente, detectado a través de la IP, los datos de la tarjeta de crédito o débito y la información que nos suministre.
            </p>
            <p>
            Servicio de soporte en los cursos: El/La usuario/usuaria debe saber que dispone de un servicio de soporte para plantear aquellas dudas surgidas en relación con el temario impartido en el mismo. Ahora bien, dicho soporte sólo puede versar sobre el temario, estando excluido cualquier otro como - entre otros - el soporte técnico para incidencias particulares, soporte relacionado con la tecnología que los soporta, etc.<br/>
            Puede contactar con el servicio de soporte enviando un correo electrónico a hola@yoguic.com
            </p>
            <p>
            <b>Tercera, el proceso de compra: </b>Para realizar un pedido en nuestra web es necesario registrarse durante el proceso de compra. Nuestra tienda crea una cuenta de cliente, con la dirección de correo electrónico y la contraseña que se elija, para facilitar el inicio de sesión y las futuras compras. Estos datos serán tratados e incorporados a nuestros ficheros, tal como se informa en nuestro apartado de protección de datos de carácter personal contenido en la política de privacidad.
            </p>      
            <p>
            Para realizar una compra, debes elegir el producto o servicio y automáticamente el sistema te redirigirá a la página de pedido (carrito). En esta pantalla deberás indicar tu nombre, apellidos y correo (salvo que ya hayas iniciado sesión), además de elegir un método de pago. Una vez completado, pulsa el botón “Pagar”. Se solicitarán los datos referentes a la tarjeta con la que realizar el pago. A continuación se te mostrará una página de confirmación de la compra con una confirmación de contratación.
            </p>
            <p>
            La justificación de la recepción del producto por el destinatario será a través de la IP y orden de descarga. En el sistema del proceso de compra de nuestros productos, existe toda la información del proceso. Se considerará que se suscribe el contrato de venta con la aceptación del consentimiento informado. El consumidor, antes de 24 horas de la compra, recibirá el justificante de la transacción, y la confirmación de que el procedimiento se ha completado y que es archivado por el destinatario.
            </p>      
            <p>
            Entrega productos: En relación a las fechas de entrega, cuando se trate de infoproductos, la entrega se realizará de forma inmediata, y cuando se trate de la prestación de servicios, se notificará personalmente el plazo de ejecución del servicio. <br/>
            No hay una duración mínima en que el comprador se vea obligado a mantener nuestras relaciones comerciales. En la compra de nuestros productos no hay que aportar ningún tipo de garantía financiera ni depósito. No hay limitación geográfica de acceso a los contenidos que se venden en nuestra web. Los productos vendidos en nuestra web gozan de todos los derechos de protección de propiedad intelectual y otras garantías legales. También se utilizan todas las medidas técnicas necesarias para garantizar la seguridad de la compra y el acceso a los productos digitales de forma segura. La interoperabilidad o la capacidad que tiene nuestro producto o sistema, cuyas interfaces son totalmente conocidas, para funcionar con otros productos o sistemas existentes o futuros, sin restricción de acceso o de implementación irá en función de las necesidades técnicas y organizativas que en cada momento sean necesarias para vender los productos y prestar los servicios que se ofrecen al consumidor. El archivo del documento electrónico se hará en formato digital en los archivos electrónicos que custodia la empresa. La web yoguic.com está preparada técnicamente para identificar y corregir los errores en la introducción de los datos, siendo estos medios técnicos variables en función de la evolución de los mismos.
            </p>  
            <p>
            <b>Cuarta, anulación y devoluciones: </b>El cliente de nuestra tienda online se compromete en todo momento a facilitar información veraz sobre los datos solicitados en los diferentes formularios de compra. En el supuesto de la venta o comercialización de productos de nuestro sitio web que requieran que el comprador tenga una determinada edad según la legislación vigente de su país de origen, el comprador manifiesta que cumple dicho requisito para formalizar la compra.
            </p>    
            <p>
            Por el artículo 102 del Real Decreto Legislativo 1/2007, de 16 de noviembre el derecho de desistimiento no será aplicable a los contratos que se refieran a suministro de contenido digital (programas, aplicaciones, juegos, música, vídeos o textos informáticos, tanto por descarga o emisión en tiempo real), que no se preste en un soporte material cuando la ejecución haya comenzado. <br/>
            Por tanto, el consumidor no podrá ejercer el derecho a desistimiento en la compra de contenidos digitales de Yoguic.com. <br/>
            Al ser productos digitales, no existen gastos materiales de devolución del producto, porque se limita únicamente el acceso, por tanto no habrá reembolso de gastos. En relación a los servicios si estos no se han podido prestar por falta de cooperación del cliente, éste perderá el derecho a la devolución de lo pagado. La asistencia posventa de los infoproductos adquiridos será ejecutada si se refiere a dificultades de descarga/visualización.
            </p>                         
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export default CondicionesVenta;