import React from "react";
import { TextField, InputAdornment } from '@mui/material';
import { styled } from '@mui/system';

import MailOutlineIcon from '@mui/icons-material/MailOutline';

const StyledTextField = styled(TextField)( ({ theme }) => ({
  '& input': {
    color: theme.palette.yoguic.blanco.primario,
    paddingLeft: '5px',
  },
  '& fieldset': {    
    borderColor: theme.palette.yoguic.blanco.primario,
    borderRadius: '30px',
  }
}));

function FooterTextField(props) {
  const { onChangeValue, valueEmail } = props;

	return <StyledTextField variant="outlined" 
    aria-label="newsletter"  
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <MailOutlineIcon sx={{color: "common.white"}}/>
        </InputAdornment>
      ),
    }}
    value={valueEmail}
    onChange={onChangeValue}
  />;
}

export default FooterTextField;