import React, { useState, createContext, useEffect } from 'react'; 
import * as Api from "api/user";
import { GetShoppingCart } from "api/cart";

const token_id = "token";

const UserContext = createContext({
  email: '',
  token: '',
  details: null,
  courses: [],
  cart: [],
  isLogged: () => {},
  isAdmin: () => {},
  login: () => {},
  logout: () => {},
  updateCourses: () => {},
  updateCart: () => {},
  inLibrary: () => {},
  inLibraryCategory: () => {},
  inCart: () => {},
});

const UserProvider = (props) => {
  const [email, setEmail] = useState('');
  const [details, setDetails] = useState(null);
  const [token, setToken] = useState(localStorage.hasOwnProperty(token_id) ? localStorage.getItem(token_id) : '');
  const [courses, setCourses] = useState([]);
  const [cart, setCart] = useState([]);

  useEffect(() => {
    if (!token) { return; }
    Api.GetUserContext(token)
    .then(user => {
      setEmail(user.email);
      setCourses(user.userCourses);
      setDetails(user);
    })
    .catch(() => logout());
  }, [token]);

  useEffect(() => {
    if (!email) { return; }
    GetShoppingCart(email)
    .then(response => updateCart(response.items))
    .catch(() => setCart([]));
  }, [email]);

  const login = (email, token) => {
    setEmail(email);
    setToken(token);
    localStorage.setItem(token_id, token);
  }

  const logout = () => {
    setEmail('');
    setToken('');
    setCourses([]);
    setCart([]);
    setDetails(null);
    localStorage.removeItem(token_id);
  }

  const updateCourses = () => {
    Api.GetUserContext(token)
    .then(user => setCourses(user.userCourses))
    .catch(() => setCourses([]));
  }

  const updateCart = (cartCourses) => {
    let c = [];
    cartCourses.forEach(course => c.push(course.name))
    setCart(c);
  }

  const isLogged = () => {
    return token !== '';
  }

  const isAdmin = () => {
    console.log(JSON.stringify(details));
    return details.admin;
  }

  const inLibrary = (courseName) => {    
    for (let i = 0; i < courses.length; i++) {
      if (courses[i].name === courseName) {
        return true;
      }
    }
    return false;
  }

  const inLibraryCategory = (courseName, category) => {
    for (let i = 0; i < courses.length; i++) {
      if (courses[i].name === courseName) {
        if (courses[i].category === "mentoria") {
          return true;
        }
        if (courses[i].category === category) {
          return true;
        } 
      }
    }
    return false;
  }

  const inCart = (courseName) => {
    return cart.includes(courseName);
  }  

  return (
    <UserContext.Provider value={{email, token, details, courses, cart, login, logout, updateCourses, updateCart, isLogged, inLibrary, inLibraryCategory, inCart, isAdmin}}>
      {props.children}
    </UserContext.Provider>
  );
}

export { UserProvider };
export default UserContext;