import React from "react";
import { Box, Typography } from '@mui/material';

function BannerTexto() {   
  return (
    <Box sx={{ height: {xs: 600, md: 320}, width: 1, bgcolor: 'yoguic.verde.primario', display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: 1, maxWidth: 1440, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
        <Box sx={{ height: '90%', width: '90%', display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
          <Box sx={{ width: 1/3, height: 150, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h6" component="h4" sx={{ color: 'common.white', fontWeight: 'bold' }}>
              Comunidad
            </Typography>
            <Typography variant="subtitle1" component="div" sx={{ width: 280, color: 'common.white' }}>
              Comparte con otrxs profesionales del yoga como tú.
            </Typography>          
          </Box>
          <Box sx={{ width: 1/3, height: 150, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h6" component="h4" sx={{ color: 'common.white', fontWeight: 'bold' }}>
              Aprendizaje
            </Typography>
            <Typography variant="subtitle1" component="div" sx={{ width: 350, color: 'common.white' }}>
              Encuentra la formación que necesitas para crecer en tu desarrollo como profesional.
            </Typography>            
          </Box>
          <Box sx={{ width: 1/3, height: 150, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h6" component="h4" sx={{ color: 'common.white', fontWeight: 'bold' }}>
              Experiencia
            </Typography>
            <Typography variant="subtitle1" component="div" sx={{ width: 320, color: 'common.white' }}>
              Conoce especialistas que te inspirarán en tu camino.
            </Typography>            
          </Box>  
        </Box>                      
      </Box>
    </Box>
  );
}

export default BannerTexto;
