import React from "react";
import { Typography, IconButton, FormControlLabel } from '@mui/material';
import { useNavigate } from "react-router-dom";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CoursePath from "components/shared/coursePath";

function IrACurso(props) {
  const { course } = props;
  const navigate = useNavigate();
 
  const coursePath = CoursePath(course);

  return (
    <FormControlLabel 
      labelPlacement="start"
      sx={{ display: 'flex', justifyContent: 'flex-end', ml: 0 }}
      control={<IconButton onClick={() => navigate(coursePath)} ><ArrowRightAltIcon/></IconButton>} 
      label={<Typography variant="body2" sx={{fontWeight: 'bold'}} >Más información</Typography>}
    />
  );
}

export default IrACurso;