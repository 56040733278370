import React, { useState, useEffect, useContext } from "react";
import { Box } from '@mui/material';

import UserContext from "providers/userProvider";
import { GetCoursesList } from "api/course";

import CourseCard from "components/courses/card/courseCard";
import CoursePopup from "components/courses/popup/coursePopup";

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

function Carousel(props) {
  const { category } = props;
  const [courses, setCourses] = useState([]);
  const [open, setOpen] = useState(null);
  const [position, setPosition] = useState(null);
  
  const user = useContext(UserContext);

  useEffect(() => {
    GetCoursesList(category).then(courses => {
      setCourses(courses);
      if (courses.length > 0) {
        setPosition(0);
      }
    });
  }, [category]);
  
  const leftMovement = () => {    
    let pos = position - 1;
    if (pos < 0) {
      pos = courses.length - 1;
    }
    setPosition(pos);
  }

  const rightMovement = () => {    
    let pos = position + 1;
    if (pos === courses.length) {
      pos = 0;
    }
    setPosition(pos);
  }

  const displayButtons = courses.length > 1 ? 'flex' : 'none';

  return (
    <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', gap: 1 }}>      
      <Box onClick={leftMovement} sx={{ height: 1, display: displayButtons, alignItems: 'center' }}>
        <Box sx={{ width: 25, height: 40, bgcolor: 'common.black', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 5 }} >
          <KeyboardArrowLeftIcon fontSize="large" sx={{ color: 'common.white' }} />
        </Box>
      </Box>
      {courses[position] && <CourseCard course={courses[position]} cart={user.cart} userCourses={user.courses} onOpen={() => setOpen(courses[position])} />}
      <Box onClick={rightMovement} sx={{ height: 1, display: displayButtons, alignItems: 'center' }}>
        <Box sx={{ width: 25, height: 40, bgcolor: 'common.black', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 5 }} >
          <KeyboardArrowRightIcon fontSize="large" sx={{ color: 'common.white' }} />
        </Box>
      </Box>
      <CoursePopup course={open} handleClose={() => setOpen(null)} cart={user.cart} userCourses={user.courses} />
    </Box>   
  );
}

export default Carousel;