import React from "react";
import { Box } from '@mui/material';

function SmallBanner() {   
  return (
    <Box sx={{ height: 70, width: 1, bgcolor: 'yoguic.verde.primario' }} />
  );
}

export default SmallBanner;
