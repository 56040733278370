import React, { Fragment, useState, useContext } from "react";
import { Button, IconButton } from '@mui/material';
import { useNavigate } from "react-router-dom";

import { hideOnSmall } from '../../shared/customSizing';

import DropMenu from "./controls/dropMenu";
import CartBadge from "./controls/cartBadge";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';

import UserContext from "providers/userProvider";
import DrawerMenu from "./controls/drawerMenu";

function HeaderUser(props) {
  const { handleLogin, handleRegister } = props;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [menuElement, setMenuElement] = useState(null);
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const onMenu = (event) => {
    setMenuElement(event.currentTarget);
  }

  const onDrawerClick = (nav) => {    
    setOpenDrawer(false);
    navigate(nav);
  }

  const userNotLogger = (
    <Fragment>
      <Button onClick={() => handleLogin(true)} variant="text" sx={{ fontSize: 14, color: 'yoguic.negro.primario', display: hideOnSmall, mr: 2 }}>
        Entrar
      </Button> 
      <Button variant="contained" onClick={() => handleRegister(true) } sx={{ height: 53, width: 150, borderRadius: 10, backgroundColor: 'yoguic.negro.primario', display: hideOnSmall, mr: 2 }}>
        Crear cuenta
      </Button>      
    </Fragment>  
  )

  const userLogged = (
    <>
      <CartBadge />
      <IconButton onClick={onMenu} aria-label="profile" component="span" sx={{ display:{xs: 'none', md: 'flex'}, justifyContent: 'center', alignItems: 'center', mr: 2 }}>
        <AccountCircleIcon fontSize="large" />
      </IconButton>
    </>
  )

	return (
    <Fragment>
      {user.isLogged() ? userLogged : userNotLogger}
      <IconButton aria-label="menu" component="span" onClick={() => setOpenDrawer(true)}
        sx={{ color: 'yoguic.negro.primario', display: {xs:'flex', md: 'none'}, justifyContent: 'center', alignItems: 'center', mr: 2 }}
      >
        <MenuIcon fontSize="large"/>
      </IconButton>      
      {openDrawer && <DrawerMenu open={openDrawer} onClose={() => setOpenDrawer(false)} onClick={onDrawerClick} user={user} />}
      <DropMenu menuElement={menuElement} onClose={() => setMenuElement(null)} user={user} />
    </Fragment>
  );
}

export default HeaderUser;