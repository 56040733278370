import React, { useState, useEffect, useContext } from "react";
import { Box } from '@mui/material';

import UserContext from "providers/userProvider";
import { GetCoursesList } from "api/course";

import CourseCard from "components/courses/card/courseCard";
import CoursePopup from "components/courses/popup/coursePopup";

function CardsGrid(props) {
  const { category } = props;
  const [courses, setCourses] = useState([]);
  const [open, setOpen] = useState(null);
  const user = useContext(UserContext);

  useEffect(() => {
    GetCoursesList(category).then(courses => setCourses(courses));
  }, [category]);

  return (
    <>
      <Box sx={{ width: 1, display: 'flex', flexDirection: {xs: 'column', md: 'row'}, justifyContent: {xs: 'center', md:'space-around'}, alignItems: 'center', gap: {xs: 2, md: 0} }}>
        {
          courses.slice(0, 3).map((course, idx) => ( 
          <CourseCard key={idx} course={course} cart={user.cart} userCourses={user.courses} onOpen={() => setOpen(course)} />
        ))}
      </Box>
      <Box sx={{ width: 1, display: 'flex', flexDirection: {xs: 'column', md: 'row'}, justifyContent: {xs: 'center', md:'space-around'}, alignItems: 'center', gap: {xs: 2, md: 0} }}>
        {
          courses.slice(3, courses.length).map((course, idx) => ( 
          <CourseCard key={idx} course={course} cart={user.cart} userCourses={user.courses} onOpen={() => setOpen(course)} />
        ))}
      </Box>
      <CoursePopup course={open} handleClose={() => setOpen(null)} cart={user.cart} userCourses={user.courses} />
    </>    
  );
}

export default CardsGrid;