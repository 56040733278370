import React from "react";
import { Box } from '@mui/material';

import RectFancy from 'assets/images/dashboard/rectangulo_fancy.png';
import AprendeTexto from "./subsections/aprendeTexto";

function AprendeSection() {

	const background = { xs: 'common.white', md:'yoguic.gris.background' };

	return (
    <Box sx={{ height: 500, width: 1, display: 'flex', justifyContent: 'center', bgcolor: 'common.white'}} >
			<Box component="img" src={RectFancy} alt="fancy" sx={{ width: 1/2, display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ width: {xs: 1, md: 1/2}, bgcolor: background, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
        <Box sx={{ width: '90%', height: '80%', display: 'flex' }} >		
          <AprendeTexto />
        </Box>
      </Box>
    </Box>   
	);
}

export default AprendeSection;