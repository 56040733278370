import React, { useContext } from "react";
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";

import UserContext from "providers/userProvider";

function TitleSection() {
  const navigate = useNavigate();
  const user = useContext(UserContext);

	return (
    <Box sx={{ height: 500, width: 1,  bgcolor: 'yoguic.verde.primario', display: 'flex', justifyContent: 'center' }} >
      <Box sx={{ width: 1, maxWidth: 1440, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Box sx={{ height: '90%', width: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', gap: 1}}>
          <Typography variant="h2" component="h1" sx={{ color: 'common.white', fontStyle: 'italic' }}>
            La comunidad digital  
          </Typography>
          <Typography variant="h2" component="h1" sx={{ color: 'common.white', fontWeight: 900, letterSpacing: -1 }}> 
            de profesionales del yoga.
          </Typography>
          <Box sx={{ height: 50 }} />
          {!user.isLogged() &&
            <Button variant="contained" onClick={() => navigate("/register")} sx={{ backgroundColor: 'yoguic.rojo.primario', height: 53, width: 150, borderRadius: 10 }}>
            Crear cuenta
          </Button>
          }
        </Box>
      </Box>
    </Box>   
	);
}

export default TitleSection;