import React, { useState, useContext } from "react";
import { Typography, Button, Link, FormControl } from '@mui/material';
import { useNavigate } from "react-router-dom";

import { RoundedTextInput } from "components/shared/roundTextInput";
import LoadingBox from "components/shared/loadingBox";
import PasswordField from "components/shared/passwordField";

import * as Api from "api/login";
import { getError } from "api/apiErrors";

import UserContext from "providers/userProvider";

function LoginForm(props) { 
  const { onForgot, onClose } = props;
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [errorPass, setErrorPass] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const navigate = useNavigate();
  const user = useContext(UserContext);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handlePassChange = (event) => {    
    setPass(event.target.value);
  }

  const onLogin = () => {    
    setLoading(true);
    Api.Login(email, pass)
    .then(token => handleSuccess(token))
    .catch(error => processError(error.cause))
  }

  const handleSuccess = (token) => {
    user.login(email, token, []);
    if (onClose) {
      onClose();
    } else {
      navigate("/");
    }
    setLoading(false);
  }

  const processError = (cause) => {
    const error = getError(cause.code);
    setErrorMsg(error);
    setErrorPass(true);
    setLoading(false);
  }

  const handleKey = (event) => {
    if (event.key === 'Enter' && !disableButton) {
      onLogin();
    }
  }

  const disableButton = email.length === 0 || pass.length === 0 || !email.includes(".") || !email.includes("@");

  const loginButton = (
  <>
    <Button variant="contained" onClick={onLogin} disabled={disableButton} sx={{ height: 53, width: 150, borderRadius: 10, backgroundColor: 'yoguic.negro.primario', mr: 2 }}>
      Entrar
    </Button>
    <Link variant="caption" component="div" href="#" onClick={onForgot} >
      ¿Has olvidado la contraseña?
    </Link>  
  </>    
  );

	return (
    <FormControl onKeyDown={handleKey} sx={{ width: "90%", height: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', gap: 3 }}>
      <Typography variant="subtitle1" component="div" fontWeight="bold" sx={{ maxWidth: 400 }}>
        Iniciar sesión en Yoguic
      </Typography>
      <RoundedTextInput variant="outlined" label="Email" onChange={handleEmailChange} sx={{ width: {xs: 250, md: 350} }} />      
      <PasswordField setPass={handlePassChange} />
      {errorPass && <Typography variant="body2" component="div" sx={{ fontWeight: 'bold', color: 'yoguic.rojo.primario' }}>
        {errorMsg}
      </Typography>}
      {loading ? <LoadingBox /> : loginButton}
    </FormControl>
	);
}

export default LoginForm;