import React from "react";
import { Box } from '@mui/material';

import { halfWidthOnBig } from "../shared/customSizing";

import FooterIcons from "./footer/footerIcons";
import FooterNews from "./footer/footerNews";

function Footer() {

	return (
    <Box sx={{ minHeight: {xs: 620, md: 318}, width: 1,  bgcolor: 'yoguic.verde.primario', display: 'flex', justifyContent: 'center' }} >
      <Box sx={{ maxWidth: 1440, width: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<Box sx={{ width: '90%', height: '70%', display: 'flex', flexDirection: {xs: 'column-reverse', md: 'row'}, gap: {xs: 2, md: 0} }}>
					<Box sx={{ width: halfWidthOnBig }} >
						<FooterIcons />
					</Box>
					<Box sx={{ width: halfWidthOnBig }} >
						<FooterNews />
					</Box>
				</Box>
      </Box> 			
    </Box>   
	);
}

export default Footer;