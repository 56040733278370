import React, { Fragment, useState } from "react";

import RegisterForm from "./register/registerForm";
import RegisterConfirmation from "./register/registerConfirmation";

function RegisterComponent() { 
  const [ register, setRegister ] = useState(false);
  const [email, setEmail] = useState("");

	return (
    <Fragment>
      {register ? <RegisterConfirmation email={email} /> : <RegisterForm onRegister={() => setRegister(true)} email={email} setEmail={setEmail} />}
    </Fragment>  
	);
}

export default RegisterComponent;