const apiErrors = new Map();
apiErrors.set('E100', 'Esta cuenta de correo ya existe en Yoguic');
apiErrors.set('E102', 'Esta cuenta de correo no existe en Yoguic');
apiErrors.set('E103', 'Esta cuenta de correo ya ha sido verificada');
apiErrors.set('E104', 'Contraseña incorrecta');
apiErrors.set('E105', 'Cuenta de correo no verificada');

const generalError = 'Error en el servidor de Yoguic';

export const getError = (code) => {
  const error = apiErrors.get(code);
  if (error) {
    return error;
  }
  return generalError;
}

/*const ApiErrors = {
  NOT_FOUND: {code: "E102", text: 'Este correo no existe en el sistema'},
  ALREADY_ACTIVE: {code: "E103", text: 'Este correo ya esta activo en el sistema'},
  WRONG_PASS: {code: "E104", text: 'Contraseña incorrecta'},
  NOT_ACTIVE: {code: "E105", text: 'Cuenta de correo no verificada'},
}*/

//export default ApiErrors;