import { ClearCoursePath } from "utils/clearCoursePath";

const courseUrl = "/course"
const uri = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_STACK_NAME + courseUrl;

/*export const GetCourseDetails = (courseName) => {	  
	return fetch(uri + "/" + courseName, {method: 'GET'})
    .then(status)
    .then(response => response.json())
    .then(json => {return json})
		.catch(error => {return Promise.reject(error.status)})
}*/

const GetCoursesListFromApi = async (category) => {
  const url = new URL(uri);
  var params = {"category":category};
  url.search = new URLSearchParams(params).toString();
  const response = await fetch(url, {method: 'GET'});
  if (!response.ok) {
    return [];
  }
  return await response.json();
}

export const GetCoursesList = async (category) => {
  return await GetCoursesListFromApi(category);
}

export const GetCourseDetails = async (courseName) => {
  const url = new URL(uri + "/" + courseName); 
  const response = await fetch(url, {method: 'GET'});
  if (!response.ok) {
    throw new Error(response.status);
  }
  const details = await response.json();
  return details;
}

export const GetCourseVideo = async (courseName, chapter, unit) => {
  const name = ClearCoursePath(courseName);

  const response = await fetch(uri + "/" + name + "/video", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      chapter: chapter,
      unit: unit,
    })
  });
  if (!response.ok) {
    throw new Error(response.status);
  }
  const jsonObject = await response.json();
  //console.log(JSON.stringify(jsonObject));
  return jsonObject;
}

export const GetCourseTeaser = async (courseName) => {
  const name = ClearCoursePath(courseName);

  const response = await fetch(uri + "/" + name + "/teaser");
  if (!response.ok) {
    throw new Error(response.status);
  }
  const jsonObject = await response.json();
  //console.log(JSON.stringify(jsonObject));
  return jsonObject;
}
