import React, { useState, useEffect, useContext } from "react";
import { Box } from '@mui/material';

import UserContext from "providers/userProvider";
import { GetCoursesList } from "api/course";

import CourseCard from "components/courses/card/courseCard";
import CoursePopup from "components/courses/popup/coursePopup";

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

function CarouselLarge(props) {
  const { category } = props;
  const [courses, setCourses] = useState([]);
  const [open, setOpen] = useState(null);
  const [firstPosition, setFirstPosition] = useState(null);
  const [secondPosition, setSecondPosition] = useState(null);
  const [thirdPosition, setThirdPosition] = useState(null);
  
  const user = useContext(UserContext);

  useEffect(() => {
    GetCoursesList(category).then(courses => {
      setCourses(courses);
      if (courses.length > 0) {
        setFirstPosition(0);
        setSecondPosition(1);
        setThirdPosition(2);
      }
    });
  }, [category]);
  
  const leftMovement = () => {    
    let pos = firstPosition - 1;
    let pos2 = secondPosition - 1;
    let pos3 = thirdPosition - 1;
    setFirstPosition(checkLow(pos));
    setSecondPosition(checkLow(pos2));
    setThirdPosition(checkLow(pos3));
  }

  const rightMovement = () => {    
    let pos = firstPosition + 1;
    let pos2 = secondPosition + 1;
    let pos3 = thirdPosition + 1;
    setFirstPosition(checkHigh(pos));
    setSecondPosition(checkHigh(pos2));
    setThirdPosition(checkHigh(pos3));
  }

  const checkLow = (pos) => {
    if (pos < 0) {
      return courses.length - 1;
    }
    return pos;
  }

  const checkHigh = (pos) => {
    if (pos === courses.length) {
      return 0;
    }
    return pos;
  }

  return (
    <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', gap: 1 }}>   

      <Box onClick={leftMovement} sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: 25, height: 40, bgcolor: 'common.black', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 5 }} >
          <KeyboardArrowLeftIcon fontSize="large" sx={{ color: 'common.white' }} />
        </Box>
      </Box>

      {courses[firstPosition] &&
        <Box sx={{ height: 1, width: 1, display: 'flex', flexDirection: {xs: 'column', md: 'row'}, justifyContent: {xs: 'center', md:'space-around'}, alignItems: 'center', gap: {xs: 2, md: 0} }}>
          <CourseCard course={courses[firstPosition]} cart={user.cart} userCourses={user.courses} onOpen={() => setOpen(courses[firstPosition])} />
          <CourseCard course={courses[secondPosition]} cart={user.cart} userCourses={user.courses} onOpen={() => setOpen(courses[secondPosition])} /> 
          <CourseCard course={courses[thirdPosition]} cart={user.cart} userCourses={user.courses} onOpen={() => setOpen(courses[thirdPosition])} />
        </Box>
      }

      <Box onClick={rightMovement} sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: 25, height: 40, bgcolor: 'common.black', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 5 }} >
          <KeyboardArrowRightIcon fontSize="large" sx={{ color: 'common.white' }} />
        </Box>
      </Box>

      <CoursePopup course={open} handleClose={() => setOpen(null)} cart={user.cart} userCourses={user.courses} />
    </Box>   
  );
}

export default CarouselLarge;