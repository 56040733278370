const constants = {
  DASHBOARD : {
    HEADER : "Yoguic es",
    HEADER_ITALIC : "aprendizaje",
    SECTION_A_BOLD : "Yoguic",
    SECTION_A : "es yoga.",
    SECTION_A_TEXT : "Yoguic es la plataforma de aprendizaje para yoguis. Encontrarás cursos online siempre disponibles que te ayudarán a crecer como profesional y te acompañarán en tu práctica. ",
    BANNER_A_1 : "Cursos",
    BANNER_A_2 : "online",
    BANNER_A_3 : "para",
    BANNER_A_4 : "yoguis...",
    BANNER_A_ITALIC : " ¡creados por yoguis!",
    COURSE_TITLE : "¡Te damos la bienvenida al",
    COURSE_TITLE_BOLD : "primer curso de Yoguic!",
    COURSE_SUBTITLE : "Fundamentos y práctica de la meditación",
    COURSE_AUTHOR : "Un curso de",
    COURSE_AUTHOR_COLOR : "Mireia Castellsaguè",
    COURSE_DESCRIPTION : "Un curso dirigido a todas las personas que quieran adentrarse en la práctica de la meditación. Tanto si quieres incorporar la meditación en tus clases de yoga como si quieres aprender a meditar de forma autónoma, Mireia te da las bases teórico-prácticas para poder desarrollar una experiencia sólida. ",
    BANNER_B_1 : "Aprende de",
    BANNER_B_2 : "profesionales",
    BANNER_B_3 : "expertos,",
    BANNER_B_4 : "y",
    BANNER_B_5 : "comparte",
    BANNER_B_6 : "tu experiencia",
    SECTION_C_TITLE_BOLD : "Aprende",
    SECTION_C_TITLE : "y comparte.",
    SECTION_C_TEXT : "Yoguic trabaja en colaboración con profesionales expertos en la materia que se expone, creando cursos a partir del interés y la motivación del propio profesional por compartir su conocimiento.",
    ITEM_A_TITLE : "Aprende",
    ITEM_A_TITLE2 : "a tu ritmo.",
    ITEM_A_TEXT : "Cursos totalmente flexibles para ver a tu ritmo, sin horarios y desde cualquier dispositivo. Los cursos tienen acceso ilimitado, así podrás verlos tantas veces como necesites para profundizar en el contenido.",
    ITEM_B_TITLE : "Descubre a",
    ITEM_B_TITLE2 : "profesionales expertos.",
    ITEM_B_TEXT : "Descubre lo que cada profesional te puede aportar. Transmitido con pasión y amor, el contenido de los cursos está trabajado con mucho cariño y detalle.",
    ITEM_C_TITLE : "Realiza cursos de",
    ITEM_C_TITLE2 : "producción profesional.",
    ITEM_C_TEXT : "Yoguic produce internamente cada curso para garantizar una experiencia de aprendizaje online coherente y de altísima calidad.",
    ITEM_D_TITLE : "Comparte ideas",
    ITEM_D_TITLE2 : "y reflexiones.",
    ITEM_D_TEXT : "Utiliza las herramientas de comunidad integradas en el curso para preguntar, opinar y participar. Comparte tu experiencia generando nuevas vías de aprendizaje.",
    SECTION_E_TITLE_BOLD : "Conoce Yoguis",
    SECTION_E_TITLE : "y comparte experiencias.",
    SECTION_E_TEXT : "Yoguic es la plataforma de aprendizaje para yoguis. Encontrarás cursos online siempre disponibles que te ayudarán a crecer como profesional y te acompañarán en tu práctica. ",
  },
  ERRORS : {
    API_ERROR : "Error de conexión con el servidor de Yoguic",
    E100 : "Email ya existente",
    E101 : "Password demasiado corto",
    E102 : "Usuario no encontrado en el sistema",
    E103 : "Email de usuario ya validado",
    E104 : "Password incorrecto",
  }
}

export default constants;