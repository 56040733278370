import React from "react";
import { Box } from '@mui/material';

function PoliticaPrivacidad() {
  return (
    <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', bgcolor: 'yoguic.gris.background' }}>
      <Box sx={{ width: 1, maxWidth: 1440, display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }} >
        <Box sx={{ width: '90%' }}>
          <div>
            <h1>Política de privacidad</h1>  
            <p>
              Fecha de actualización de la política de privacidad: 16/10/2022<br/>
              En caso de duda contactar a: hola@yoguic.com
            </p>
            <h4>1. Responsable del tratamiento de tus datos</h4>
            <p>
              Identidad: Rocío Rodríguez Murillo, NIF: 53288584-E, dirección postal: C/Passavant 2,  Folgueroles 08519 (Barcelona), correo electrónico: hola@yoguic.com
            </p>
            <h4>2. Finalidad del tratamiento de tus datos personales</h4>
            <p>
              Yoguic.com informa que los datos que el usuario o usuaria voluntariamente está facilitando (nombre, apellidos y correo electrónico) serán incorporadas a sus sistemas de información con la siguiente finalidad:
            <ul>
              <li>En el caso de contratación de los cursos ofrecidos a través del sitio web, para mantener la relación contractual, así como la gestión, administración, información, prestación y mejora del servicio ofrecido.</li>
              <li>En el caso de suscripción, para remitir boletines (newsletters), así como comunicaciones comerciales de promociones o publicidad del sitio web.</li>
            </ul>
            </p>
            <h4>3. Cesiones de datos a terceros</h4>
            <p>
              Yoguic.com (titularidad de Rocío Rodríguez Murillo) informa que no cederá los datos personales a terceros, excepto por obligación legal. Sin embargo, en el caso de ser cedidos a algún tercero, se produciría una información previa solicitando el consentimiento expreso para tal cesión. 
            </p>
            <p>
              El prestador tiene contratado el siguiente proveedor de servicios, con el que se han comprometido al cumplimiento de las disposiciones normativas de aplicación en materia de protección de datos, en el momento de su contratación:oguic.com (titularidad de Rocío Rodríguez Murillo) informa que no cederá los datos personales a terceros, excepto por obligación legal. Sin embargo, en el caso de ser cedidos a algún tercero, se produciría una información previa solicitando el consentimiento expreso para tal cesión. 
            </p>
            <p>
              - Stripe Spain Sl., con domicilio en Calle Zurbaran, 9 - LOC DR, Madrid, 28010 , España, proporciona los servicios para gestionar los pagos online de una forma segura. Puedes consultar la política de privacidad y otros aspectos legales de la compañía en el enlace siguiente: https://stripe.com/es/privacy.
            </p>
            <h4>4. Derechos de acceso, rectificación, cancelación y oposición de los usuarios y usuarias</h4>
            <p>
              Yoguic.com (titularidad de Rocío Rodríguez Murillo) garantiza, en todo caso, el ejercicio de los derechos de acceso, rectificación, supresión (olvido), limitación del tratamiento, portabilidad y oposición, en los términos dispuestos en la legislación vigente. Por eso, cualquier usuario o usuaria podrá ejercer sus derechos remitiendo una solicitud expresa en la dirección mencionada en la cabecera o enviando un correo electrónico a hola@yoguic.com, indicando en el asunto: Derechos ARCO, y adjuntando el nombre del titular de los datos personales y los documentos que acrediten la identidad del titular
            </p>
            <h4>5. Medidas de seguridad adoptadas para proteger la seguridad de la información almacenada de carácter personal</h4>
            <p>
              Los datos facilitados se conservarán mientras haya un interés mutuo por mantener la finalidad del tratamiento y durante el plazo por el que pudieran derivarse responsabilidades legales por los servicios prestados. Cuando ya no sean necesarias para la finalidad por la que han sido recogidas, se suprimirán con medidas de seguridad adecuadas para garantizar el anonimato de los datos o su destrucción total. El/la prestador/a se reserva el derecho a modificar la presente política para adaptarla a novedades legislativas o jurisprudenciales.
            </p>
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export default PoliticaPrivacidad;