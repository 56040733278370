import { ClearCoursePath } from "utils/clearCoursePath";

export function GetThumbnail(courseName) {
  return (courseName) ? require("assets/images/course/thumbnails/" + courseName + ".thumbnail.jpg") : '';
}

export function GetBioImage(courseName) {
  const name = ClearCoursePath(courseName);
  return (courseName) ? require("assets/images/course/bio/" + name + ".bio.jpg") : '';
}

export function GetPersonaImage(id) {
  return (id) ? require("assets/images/conocenos/bio/"+id+".bio.png") : '';
}
