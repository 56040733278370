import React from "react";
import { Box, Typography, Link, FormControlLabel, Checkbox } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";

function Checkboxes(props) { 
  const { policy, setPolicy, newsletter, setNewsletter } = props;

  const politica = (
    <Link variant="inherit" display="inline" component={RouterLink} to="/legal/privacidad" href="#" target="_blank">
      política de privacidad.
    </Link>
  );

	return (
    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
      <FormControlLabel 
        control={<Checkbox size="small" checked={policy} onChange={event => setPolicy(event.target.checked)} />} 
        label={<Typography variant="caption" >Para continuar debe aceptar nuestra {politica}</Typography>}
      />
      <FormControlLabel 
        control={<Checkbox size="small" checked={newsletter} onChange={event => setNewsletter(event.target.checked)} />} 
        label={<Typography variant="caption" >¿Quieres recibir las newsletter de yoguic?</Typography>}
      />
    </Box>
	);
}

export default Checkboxes;