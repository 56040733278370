import React from "react";
import { Box, Typography } from '@mui/material';

function RegisterConfirmation(props) { 
  const { email } = props;  

	return (
    <Box sx={{ width: '90%', height: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', gap: 3 }}>
      <Typography variant="subtitle1" component="div" fontWeight="bold">
        ¡El proceso de registro se ha iniciado satisfactoriamente!
      </Typography>
      <Typography variant="body1" component="div">
        En breves instantes recibirá un correo de confirmación en la dirección '{email}'
      </Typography>
      <Typography variant="body2" component="div">
        Si pasados unos minutos no llega ningún correo, por favor revise su carpeta de spam
      </Typography>
    </Box>
	);
}

export default RegisterConfirmation;