import React from "react";
import { Box, CircularProgress } from '@mui/material';

function LoadingBox() {   
	return (
    <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </Box>    
	);
}

export default LoadingBox;