import React from "react";
import { Box, Typography } from '@mui/material';

import NewsletterControl from "components/shared/newsletter/newsletterControl";

function BannerNewsletter() {   
  return (
    <Box sx={{ height: {xs: 600, md: 320}, width: 1, bgcolor: 'yoguic.verde.primario', display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: 1, maxWidth: 1440, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
        <Box sx={{ height: '90%', width: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', gap: 3}}>
         <Typography variant="h5" component="h4" sx={{ color: 'common.white', fontWeight: 'bold' }}>
            ¿Preparadx para crecer?
          </Typography>
          <Typography variant="subtitle1" component="div" sx={{ width: {xs: 1, md: 530}, color: 'common.white' }}>
            Únete a Yoguic y recibe contenido exclusivo en formato entrevistas, directos, reflexiones y descuentos.
          </Typography>
          <NewsletterControl />
        </Box>                      
      </Box>
    </Box>
  );
}

export default BannerNewsletter;
