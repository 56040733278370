import React from "react";
import { Box, Typography } from '@mui/material';

import { centerItemsOnSmall, centerTextOnSmall } from '../../shared/customSizing';

function SectionDItem(props) {
	const { head1, head2, body, imageUrl } = props;

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: centerItemsOnSmall }} >
			<Box component="img" src={imageUrl} alt="iconito" sx={{ width: 40, mb: 2 }} />
			<Typography variant="h6" sx={{ color: 'yoguic.negro.primario', fontWeight:'bold', width: 1, textAlign: centerTextOnSmall }}>
				{head1}
			</Typography>
			<Typography variant="h6" sx={{ color: 'yoguic.negro.primario', fontWeight:'bold', width: 1, mb: 2, textAlign: centerTextOnSmall }}>
				{head2}
			</Typography>  										
			<Typography variant="body2" sx={{ color: 'yoguic.negro.primario', width: { xs: 1, md: 290 }, textAlign: centerTextOnSmall }}>
				{body}				
			</Typography>
		</Box>
	);
}

export default SectionDItem;