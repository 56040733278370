import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { IconButton, Badge } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined';

import { hideOnSmall } from 'components/shared/customSizing';
import UserContext from "providers/userProvider";

function CartBadge() {
  const navigate = useNavigate();
  const user = useContext(UserContext);

	return (
    <IconButton onClick={() => navigate('/cart')} aria-label="checkout" component="span" sx={{ color: 'yoguic.gris.medio', display: hideOnSmall }}>
      <Badge badgeContent={user.cart.length} color="primary" variant="dot">
        <ShoppingCartIcon fontSize="large" />
      </Badge>
    </IconButton>
  );
}

export default CartBadge;