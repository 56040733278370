import React from "react";
import { Modal, Box } from '@mui/material';

import yoguicLogo from "assets/images/navigation/logo_cabecera.png";

import LoginComponent from "components/shared/account/loginComponent";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 530,
  height: 500,
  bgcolor: 'yoguic.gris.background',
  boxShadow: 24,
  borderRadius: 5,  
  display: 'flex',  
  flexDirection: 'column', 
  alignItems: 'center'
};

function LoginPopup(props) { 
  const { open, handleLogin } = props;

  const handleClose = () => {
    handleLogin(false);
  }

	return (
    <Modal open={open} onClose={(handleClose)}>
      <Box sx={style}>  
        <Box sx={{ width: "90%", height: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <LoginComponent onClose={handleClose} />
          <Box component="img" src={yoguicLogo} sx={{ maxWidth: 150, mb: 2 }} />
        </Box>
      </Box> 
    </Modal >   
	);
}

export default LoginPopup;