import React from "react";
import { Box, Paper } from '@mui/material';

import CourseButton from "../courseButton";
import IrACurso from "./irACurso";

import CardImage from "./cardImage";
import CardText from "./cardText";

function CourseCard(props) {
  const { course } = props;

  return (
    <Paper sx={{ width: {xs: 1, md: 420}, height: 650, display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection: 'column', bgcolor: 'common.white', borderRadius: 5 }} >
      <Box sx={{ width: '90%', height: '95%',  display: 'flex', flexDirection: 'column', gap: 2 }}>
        <CardImage {...props} />
        <CardText {...props} /> 
        <IrACurso course={course} />
        <Box sx={{ flexGrow: 1 }} />
        <CourseButton {...props}  />  
        <Box sx={{ height: '1px' }} />
      </Box>
    </Paper>
  );
}

export default CourseCard;