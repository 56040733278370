import * as GA from './events';

const baseUrl = "/cart"
const uri = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_STACK_NAME + baseUrl;

export const GetShoppingCart = async (email) => {
  const response = await fetch(uri + "/" + email, {method: 'GET'});
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  return await response.json();
}

export const AddCourseToCart = async (email, courseName, category) => {
  if (email === '' || courseName === '') {
    throw new Error("Missing parameters");
  }
  const response = await fetch(uri + "/" + email, {
    method: 'PATCH',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      course: courseName,
      category: category
    })
  });
  if (!response.ok) {
    throw new Error(response.status);    
  }
  const json = await response.json();
  GA.eventOnAddToCart();
  return json;
}

export const RemoveCourseFromCart = async (email, courseName) => {
  const response = await fetch(uri + "/" + email, {
    method: 'DELETE',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      course: courseName,
    })
  });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  return await response.json();
}
