import React, { Fragment, Suspense, lazy } from "react";
import CssBaseline from '@mui/material/CssBaseline'
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Box, CircularProgress } from '@mui/material';
import theme from './theme';
import './App.css'

import { usePageTracking } from "utils/usePageTracking";

import Main from './components/main';

import AvisoLegal from "components/navigation/legal/avisoLegal";
import PoliticaCookies from "components/navigation/legal/politicaCookies";
import CondicionesVenta from "components/navigation/legal/condicionesVenta";
import PoliticaPrivacidad from "components/navigation/legal/politicaPrivacidad";

import Contact from "components/about/contact";
import InProgress from "components/about/inProgress";
import NotFound from "components/about/notFound";
//import ComingSoon from "components/about/comingSoon";
import CourseExample from "components/courses/example/courseExample";

import Home from "components/home/dashboard";

const Register = lazy(() => import('./components/profile/register'));
const UpdateForgotPass = lazy(() => import('./components/navigation/register/updateForgotPass'));
const MailValidation = lazy(() => import('./components/navigation/register/mailValidation'));

const YoguisList = lazy(() => import('./components/yoguis/yoguisList'));
const SchoolsList = lazy(() => import('./components/schools/schoolsList'));
const PersonalSpace = lazy(() => import('./components/profile/personalSpace'));

const Cart = lazy(() => import('./components/shopping/cart'));
const Confirmation = lazy(() => import('./components/shopping/confirmation'));

const CoursesHome = lazy(() => import('./components/courses/coursesHome'));
const CourseDetails = lazy(() => import('./components/courses/details/courseDetails'));

const ProgramsHome = lazy(() => import('./components/programs/programsHome'));

const Conocenos = lazy(() => import('./components/conocenos/conocenos'));

const Admin = lazy(() => import('./components/admin/admin'));

const Jobs = lazy(() => import('./components/jobs/jobsHome'));

const loading = (
  <Box sx={{ height: 450, width: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <CircularProgress />
  </Box>  
);

function App() {
  usePageTracking();

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={loading}>
          <Routes>
            <Route path="/" element={<Main />}>
              <Route index element={<Home />} />            
              <Route path="register/:encodedMail" element={<MailValidation />} />
              <Route path="register" element={<Register />} />
              <Route path="profile/password/:encodedMail" element={<UpdateForgotPass />} />
              <Route path="profile" element={<PersonalSpace />} />
              <Route path="courses/pranayamas/teaser" element={<CourseExample />} />
              <Route path="courses/:courseName" element={<CourseDetails />} />
              <Route path="courses" element={<CoursesHome />} />
              <Route path="programs" element={<ProgramsHome />} />
              <Route path="yoguis" element={<YoguisList />} />
              <Route path="schools" element={<SchoolsList />} />
              <Route path="cart/confirmacion" element={<Confirmation />} />
              <Route path="cart" element={<Cart />} />
              <Route path="conocenos" element={<Conocenos />} />
              <Route path="contacto" element={<Contact />} />
              <Route path="obras" element={<InProgress />} />
              <Route path="admin" element={<Admin />} />
              <Route path="jobs" element={<Jobs />} />
              <Route path="*" element={<NotFound />} />
            </Route>         
            <Route path="legal/avisolegal" element={<AvisoLegal />} />
            <Route path="legal/cookies" element={<PoliticaCookies />} />
            <Route path="legal/ventas" element={<CondicionesVenta />} />
            <Route path="legal/privacidad" element={<PoliticaPrivacidad />} />
          </Routes>   
        </Suspense>             
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
