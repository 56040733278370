import React from "react";
import { Box } from '@mui/material';
import constants from "../../../assets/i18n/constants_es";
import { columnOnSmall } from '../../shared/customSizing';

import Iconito1 from '../../../assets/images/dashboard/iconito1.png';
import Iconito2 from '../../../assets/images/dashboard/iconito2.png';
import Iconito3 from '../../../assets/images/dashboard/iconito3.png';
import Iconito4 from '../../../assets/images/dashboard/iconito4.png';

import SectionDItem from "./sectionDItem";

function SectionD() { 

	return (
    <Box sx={{ minHeight: {xs: 900, md: 350}, width: 1, display: 'flex', justifyContent: 'center', bgcolor: 'common.white' }} >
			<Box sx={{ width: 1, maxWidth: 1440, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<Box sx={{ width: '90%', height: '80%', display: 'flex', justifyContent: 'center', flexDirection: columnOnSmall, gap: 2 }}>
					<Box sx={{ width: {xs: 1, md: 1/4} }}>
						<SectionDItem head1={constants.DASHBOARD.ITEM_A_TITLE} head2={constants.DASHBOARD.ITEM_A_TITLE2} body={constants.DASHBOARD.ITEM_A_TEXT} imageUrl={Iconito1} />
					</Box>
					<Box sx={{ width: {xs: 1, md: 1/4} }}>
						<SectionDItem head1={constants.DASHBOARD.ITEM_B_TITLE} head2={constants.DASHBOARD.ITEM_B_TITLE2} body={constants.DASHBOARD.ITEM_B_TEXT} imageUrl={Iconito2} />
					</Box>
					<Box sx={{ width: {xs: 1, md: 1/4} }}>
						<SectionDItem head1={constants.DASHBOARD.ITEM_C_TITLE} head2={constants.DASHBOARD.ITEM_C_TITLE2} body={constants.DASHBOARD.ITEM_C_TEXT} imageUrl={Iconito3} />
					</Box>
					<Box sx={{ width: {xs: 1, md: 1/4} }}>
						<SectionDItem head1={constants.DASHBOARD.ITEM_D_TITLE} head2={constants.DASHBOARD.ITEM_D_TITLE2} body={constants.DASHBOARD.ITEM_D_TEXT} imageUrl={Iconito4} />
					</Box>
				</Box>
			</Box>
    </Box>   
	);
}

export default SectionD;