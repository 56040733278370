import React, { useState } from "react";
import { Box, Button, Typography } from '@mui/material';

import { centerTextOnSmall, centerItemsOnSmall } from "../../shared/customSizing";
import { RegisterEmailNewsletter } from "../../../api/user";
import FooterTextField from "./footerTextField";

function FooterNews() {  	
	const [email, setEmail] = useState("");
	const [newsletter, setNewsletter] = useState(false);

	const onChangeEmail = event => {
		setEmail(event.target.value);
	}

  const onNewsletter = () => {    
		if (email.includes(".") && email.includes("@")) {
			RegisterEmailNewsletter(email);
			setNewsletter(true);
		}
  }

	const registroNewsletter = (
		<Box sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'row'}, alignItems: centerItemsOnSmall, gap: 2 }}>
			<Box sx={{ maxWidth: { xs: 1, md: 300 } }}>
				<FooterTextField onChangeValue={onChangeEmail} valueEmail={email} />	
			</Box>
			<Button variant="contained" onClick={onNewsletter} sx={{ backgroundColor: 'yoguic.rojo.primario', height: 50, width: 110, borderRadius: 10 }}>
				Enviar
			</Button>
		</Box>
	);

	const confirmacionNewsletter = (
		<Typography variant="caption" sx={{ color: 'common.white', textAlign: centerTextOnSmall }}>
			{email} añadido a la lista de newsletter!
		</Typography>	
	);

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: 5}} >
			<Box sx={{display: 'flex', flexDirection: 'column', alignItems: centerItemsOnSmall, gap: 1 }}>
				<Typography variant="h6" sx={{ color: 'common.white', fontWeight: 700 }}>
					¿Quieres recibir la newsletter?
				</Typography>
				<Typography variant="body1" sx={{ color: 'common.white', textAlign: centerTextOnSmall }}>
					Sé el primero en recibir las últimas novedades sobre Yoguic.
				</Typography>			
			</Box>			
			{newsletter ? confirmacionNewsletter : registroNewsletter}
		</Box> 
	);
}

export default FooterNews;