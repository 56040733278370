import React from "react";
import { Box, Typography } from '@mui/material';

import ImagenRaruna from "assets/images/about/imagen_raruna.png";

function InProgress() {   
	return (
    <Box sx={{ width: 1, minHeight: 660, display: 'flex', justifyContent: 'center', bgcolor: 'yoguic.gris.background', mb: 2 }}>
      <Box sx={{ maxWidth: 1440, width: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}> 
        <Box sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'row'}, alignItems: 'center', gap: 2 }} >
          <Box component="img" src={ImagenRaruna} alt="inProgress" sx={{ maxWidth: 145 }} />
          <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: {xs: 'center', md: 'left'}, gap: 1 }} >
            <Typography variant="h4" component="div" fontWeight="bold" sx={{ color: 'yoguic.negro.primario' }}>
              ¡Esta página está en desarrollo!
            </Typography>
            <Typography variant="body1" component="div" sx={{ color: 'yoguic.negro.primario' }}>
            En breves tendremos esta página funcionando.
            </Typography>
            <Typography variant="body1" component="div" sx={{ color: 'yoguic.negro.primario' }}>
            De mientras, no te pierdas todas nuestras novedades ¡suscribiéndote a la newsletter!
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>    
	);
}

export default InProgress;