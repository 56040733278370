import React, { useState } from "react";
import { Box, Button, Typography } from '@mui/material';

import { RegisterEmailNewsletter } from "api/user";
import NewsletterTextField from "./newsletterTextField";

function NewsletterControl() {  	
	const [email, setEmail] = useState("");
	const [newsletter, setNewsletter] = useState(false);

	const onChangeEmail = event => {
		setEmail(event.target.value);
	}

  const onNewsletter = () => {    
		if (email.includes(".") && email.includes("@")) {
			RegisterEmailNewsletter(email);
			setNewsletter(true);
		}
  }

	const registroNewsletter = (
		<Box sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'row'}, alignItems: { xs: 'center', md: 'flex-start' }, gap: 2 }}>
			<Box sx={{ maxWidth: { xs: 1, md: 300 } }}>
				<NewsletterTextField onChangeValue={onChangeEmail} valueEmail={email} />	
			</Box>
			<Button variant="contained" onClick={onNewsletter} sx={{ backgroundColor: 'yoguic.rojo.primario', height: 50, width: 110, borderRadius: 10 }}>
				Enviar
			</Button>
		</Box>
	);

	const confirmacionNewsletter = (
		<Typography variant="caption" sx={{ color: 'common.white', textAlign: { xs: 'center', md: 'left'} }}>
			{email} añadido a la lista de newsletter!
		</Typography>	
	);

	return (
		<>	
			{newsletter ? confirmacionNewsletter : registroNewsletter}
		</> 
	);
}

export default NewsletterControl;