import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";

import * as Api from "api/course";

import VideoReact from "components/shared/video/videoReact";

function CourseExample() {
  const [url, setUrl] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
		Api.GetCourseVideo('pranayamas', 11, 1)
		.then(url => setUrl(url));
  }, [])

	return (
    <Box sx={{ height: 1200, width: 1, display: 'flex', justifyContent: 'center', bgcolor: 'yoguic.gris.background' }}>
      <Box sx={{ height: 1, width: 1, maxWidth: 1440, display: 'flex', justifyContent: 'center', alignItems:'center' }}>
        <Box sx={{ height: '90%', width: { xs: '90%', md: 890}, display: 'flex', flexDirection: 'column', alignItems:'center', textAlign: 'center', gap: 3 }}>
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems:'center'}}>
            <Typography variant="h3" component="div" sx={{ color: 'common.black', fontWeight: 'bold' }}>
              ¿Preparadx para empezar
            </Typography>
            <Typography variant="h3" component="div" sx={{ color: 'common.black', fontWeight: 'bold' }}>
              a aprender con Yoguic?
            </Typography>
          </Box>
          <Typography variant="body1" component="div" sx={{ color: 'common.black' }}>
            Si estás aquí es porque quieres dedicarte unos minutos a ti, a tu aprendizaje. 
            Permite que esta práctica de pranayama te acompañe en este proceso.
          </Typography>          
          <Box sx={{ width: {xs: 1, md: 880}, height: 480 }}>
            <VideoReact video={url} />
          </Box>
          <Typography variant="body1" component="div" sx={{ color: 'common.black', fontWeight: 'bold' }}>
            Práctica de pranayama terapéutico,   
            <Typography variant="body1" component="div" sx={{ display: "inline", fontWeight: 'regular' }}> con </Typography>
            <Typography variant="body1" component="div" sx={{ display: "inline", color: "yoguic.rojo.primario" }}> Judith Estrada.</Typography>
          </Typography>
          <Typography variant="body1" component="div" sx={{ color: 'common.black' }}>
            Esta es una de las prácticas de pranayama que encontrarás en el curso de Profundización en Pranayama, de Judith Estrada. 
                
          </Typography>
          <Typography variant="body1" component="div" sx={{ color: 'common.black' }}>
            Busca un lugar cómodo donde realizar esta práctica, necesitarás unos 15 minutos aproximadamente.
            ¡Esperemos que os guste tanto como a nosotrxs! 
          </Typography>
          <Typography variant="body1" component="div" sx={{ color: 'common.black' }}>
            Y si te has quedado con ganas de más después de la práctica, no te pierdas el curso completo de Judith.            
          </Typography>
          <Button variant="contained" onClick={() => navigate("/courses/pranayamas")} sx={{ backgroundColor: 'yoguic.amarillo.primario', height: 53, width: 150, borderRadius: 10, mt: 2 }}>
            Ver curso
          </Button>                 
        </Box>        
      </Box>
    </Box> 
	);
}

export default CourseExample;