import React from "react";
import { Menu, MenuItem, ListItemIcon, Typography  } from '@mui/material';
import { useNavigate } from "react-router-dom";

import LogoutIcon from '@mui/icons-material/Logout';

function DropMenu(props) {
  const { menuElement, onClose, user } = props;
  const open = Boolean(menuElement);
  const navigate = useNavigate();

  const onDisconect = () => {
    user.logout();
    onClose();
    navigate("/");
  };

	return (
    <Menu id="basic-menu" anchorEl={menuElement} open={open} onClose={onClose} >
      <MenuItem onClick={onDisconect}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="body2" component="div" color="text.secondary">
          Desconectar
        </Typography>
      </MenuItem>
    </Menu>
  );
}

export default DropMenu;