import React, { Fragment, useState, useEffect, useContext } from "react";
import { Box, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import UserContext from "providers/userProvider";

import CoursePath from "components/shared/coursePath";

import { AddCourseToCart } from "api/cart";

function CourseButton(props) {
  const { course } = props;
  const [inCart, setInCart] = useState(false);
  const [inLibrary, setInLibrary] = useState(false);
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const coursePath = CoursePath(course);

  useEffect(() => {
    setInCart(user.inCart(course.name));
  }, [course, user]);

  useEffect(() => {
    const response = user.inLibrary(course.name);
    setInLibrary(response);
  }, [course, user]);

  const onClickPurchase = () => {
    if (user.isLogged()) {
      AddCourseToCart(user.email, course.name, course.category)
        .then(response => user.updateCart(response.items));
    } else {
      navigate("/register");
    }
  }

  /*const descuentoNavidad = (
    <>
      <Typography variant="caption" component="div" sx ={{ color: 'yoguic.rojo.primario' }}>
        Descuento del {course.prices.discount}% hasta el 6 de enero <Typography variant="inherit" sx={{textDecoration: 'line-through', fontWeight: 'bold'}} display="inline">{course.originalPrice} €</Typography>
      </Typography>
    </>
  )*/

  const purchaseButton = (
    <>
      <Box sx={{width: 1, display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" onClick={onClickPurchase} startIcon={<ShoppingCartIcon />} sx={{ width: '75%', height: 55, bgcolor:'yoguic.amarillo.primario', borderRadius: 10 }}>
          Precio {course?.prices.price} €
        </Button>
      </Box>
    </>
  );

  const inCartButton = (
    <Box sx={{width: 1, display: 'flex', justifyContent: 'center' }}>
      <Button variant="contained" onClick={() => navigate("/cart")} startIcon={<ShoppingCartIcon />} sx={{ width: '75%', height: 55, bgcolor:'yoguic.verde.primario', borderRadius: 10 }}>
        Ver carrito
      </Button>
    </Box>
  );

  const inLibraryButton = (
    <Box sx={{width: 1, display: 'flex', justifyContent: 'center' }}>
      <Button variant="contained" onClick={() => navigate(coursePath)} sx={{ width: '75%', height: 55, bgcolor:'yoguic.verde.secundario', borderRadius: 10 }}>
        Ver curso
      </Button>
    </Box>
  );

  const noButton = (
    <></>
  );

  const buttonSelector = () => {
    if (course.category === 'mentoria') {
      return noButton;
    }
    if (inLibrary) {
      return inLibraryButton;
    }
    if (inCart) {
      return inCartButton;
    }
    return purchaseButton;
  }

  return (
    <Fragment>
      {/*(inLibrary) ? inLibraryButton : (inCart) ? inCartButton : purchaseButton*/}
      {buttonSelector()}
    </Fragment>
  );
}

export default CourseButton;