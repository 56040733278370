import React from "react";
import { Box, Typography } from '@mui/material';

function SubtitleSection() {

	return (
    <Box sx={{ height: 350, width: 1,  bgcolor: 'common.white', display: 'flex', justifyContent: 'center' }} >
      <Box sx={{ width: 1, maxWidth: 1440, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Box sx={{ height: '90%', width: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
          <Typography variant="h6" component="div" >
            Somos un ecosistema digital de conocimiento y apoyo que trabaja para generar un impacto positivo  
          </Typography>
          <Typography variant="h6" component="div" >
            en la vida profesional de los profesorxs de yoga. 
          </Typography>
          <Box sx={{ height: 30 }}/>
          <Typography variant="h6" component="div" sx={{fontWeight: 'bold'}}>
            ¿Y esto qué quiere decir? Quiere decir que si eres profesxr de yoga, esta es tu web. 
          </Typography>          
        </Box>
      </Box>
    </Box>   
	);
}

export default SubtitleSection;