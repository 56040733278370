import React from "react";
import { Paper, Box, Typography, Link } from '@mui/material';

function Contact() {   
	return (
    <Box sx={{ width: 1, minHeight: 550, display: 'flex', justifyContent: 'center', bgcolor: 'yoguic.gris.background', mb: 2 }}>
      <Box sx={{ maxWidth: 1440, width: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}> 
        <Paper sx={{ height: {xs: 450, md: 250}, width: '90%', display: 'flex', justifyContent: 'center', my: 2, borderRadius: 5 }}>
          <Box sx={{ width: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', gap: 1 }}>
            <Typography variant="h4" component="div" fontWeight="bold" sx={{ width: 1, color: 'yoguic.negro.primario' }}>
              ¿Quieres contactar con Yoguic?
            </Typography>
            <Typography variant="body1" component="div" sx={{ width: 1, color: 'yoguic.negro.primario' }}>
              Si tienes cualquier pregunta o simplemente quieres saludarnos, envíanos un mail a
              <Typography variant="inherit" display="inline" sx={{ color: 'yoguic.rojo.secundario' }}> hola@yoguic.com</Typography>
              , te responderemos con la mayor brevedad posible.
            </Typography>
            <Typography variant="body1" component="div" sx={{ width: 1, color: 'yoguic.negro.primario' }}>
              Estamos en <Link display="inline" href="https://www.instagram.com/yoguic_/" target="_blank" sx={{ color: 'yoguic.rojo.secundario' }}>Instagram</Link>, y ¡seguro que te encanta seguirnos!              
            </Typography>      
          </Box>              
        </Paper>
      </Box>
    </Box>    
	);
}

export default Contact;