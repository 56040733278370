import React, { Fragment } from "react";

import TitleSection from "./sections/titleSection";
import SubtitleSection from "./sections/subtitleSection";

import SmallBanner from "./banners/smallBanner";
import BannerTexto from "./banners/bannerTexto";
import BannerNewsletter from "./banners/bannerNewsletter";

import JuntxsSection from "./sections/juntxsSection";
import AprendeSection from "./sections/aprendeSection";
import SectionD from "./sections/sectionD";

import CourseSection from "./sections/courseSection";
import MentoriaSection from "./sections/mentoriaSection";

function Dashboard() {
  return (
    <Fragment>
      <TitleSection />
      <SubtitleSection />
      <SmallBanner />
      <JuntxsSection />
      <BannerTexto />
      <CourseSection />
      <MentoriaSection />
      <BannerNewsletter />
      <AprendeSection />
      <SectionD />      
    </Fragment>
  );
}

export default Dashboard;