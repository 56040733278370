import React from "react";
import { Box } from '@mui/material';

import JuntxsTexto from "./subsections/juntxsTexto";

import HomeChicas from "assets/images/dashboard/home_chicas.jpg";

function JuntxsSection() {

	return (
    <Box sx={{ height: 610, width: 1,  bgcolor: 'common.white', display: 'flex', justifyContent: 'center' }} >
      <Box sx={{ width: 1, maxWidth: 1440, display: 'flex', justifyContent: 'center'}}>
        <Box sx={{ width: {xs: '90%', md: 1/2} }}>
          <JuntxsTexto />
        </Box>
        <Box sx={{ width: 1/2, display: {xs: 'none', md: 'flex'}, justifyContent: 'center', alignItems: 'center'}}>
          <Box component="img" src={HomeChicas} alt="Juntxs" sx={{ width: '90%', height: '90%'}}/>
        </Box>        
      </Box>
    </Box>   
	);
}

export default JuntxsSection;