import React from "react";
import { Drawer } from '@mui/material';

import DrawerList from "./drawerList";

function DrawerMenu(props) {
  const { open, onClose } = props;

  console.log(open);

	return (
    <Drawer open={open} onClose={onClose} anchor="right">
      <DrawerList {...props} />
    </Drawer>
  );
}

export default DrawerMenu;