import React from "react";
import { Box } from '@mui/material';

function PoliticaCookies() {
  return (
    <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', bgcolor: 'yoguic.gris.background' }}>
      <Box sx={{ width: 1, maxWidth: 1440, display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }} >
        <Box sx={{ width: '90%' }}>
          <div>
            <h1>Política de cookies</h1>  
            <p>
            Fecha de actualización de la política de cookies: 16/10/2022<br/>
            En caso de duda contactar a: hola@yoguic.com
            </p>
            <p>
            Una cookie es un fichero que se descarga en su ordenador al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario.
            </p>
            <h4>¿Qué tipos de cookies hay?</h4>
            <p>
            Cookies exceptuadas:<br/>
            Las denominadas cookies exceptuadas son aquellas que no requieren el consentimiento previo del usuario para poder usarse y que se corresponden con las cookies técnicas y las cookies de personalización (de las que hablaremos más adelante). Estas cookies se consideran necesarias para el correcto funcionamiento de la web y, por tanto, pueden usarse sin el consentimiento de los usuarios.<br/>
            Cookies no exceptuadas:<br/>
            El resto de cookies son las cookies exceptuadas y requieren todas el consentimiento expreso y previo de los usuarios para poder usarlas, es decir, el aviso y la política de cookies deben informar sobre ellas y, además, permitir su aceptación o rechazo de forma sencilla.
            </p>
            <h4>¿Qué tipos de cookies utiliza esta página web?</h4>
            <p>
            Yoguic.com solamente utiliza cookies exceptuadas. utiliza cookies técnicas y de personalización:<br/>
            Cookies técnicas: Las cookies técnicas son las que permiten al usuario navegar por una página web, sitio online o aplicación y utilizar sus servicios o funciones, como por ejemplo acceder a zonas restringidas, recordar los elementos de un carrito, realizar el proceso de inscripción, almacenar contenidos para la difusión de vídeos, etc.<br/>
            Cookies de personalización: Estas cookies almacenan preferencias de experiencia del usuario. Por ejemplo, si un usuario ha seleccionado un idioma preferido.
            </p>
            <h4>¿Cómo administrar cookies en el navegador?</h4>
            <p>
            El usuario acepta expresamente, por la utilización de esta web, el tratamiento de la información recabada en la forma y con los fines anteriormente mencionados. Y asimismo reconoce conocer la posibilidad de rechazar el tratamiento de tales datos o información rechazando el uso de Cookies mediante la selección de la configuración apropiada a tal fin en su navegador. Si bien esta opción de bloqueo de Cookies en su navegador puede no permitirle el uso pleno de todas las funcionalidades del website. Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador.
            </p>
            <p>
            A continuación, se exponen las formas de configurar la aparición de cookies en los navegadores más comunes:
            </p>
            <ul>
              <li>Firefox: Opciones\Privacidad\Rastreo\indicar los sitios web donde no deseo ser rastreado.</li>
              <li>Chrome: Menú de Chrome\Configuración\Mostrar opciones avanzadas\Privacidad\Configuración del contenido\Cookies\Bloquear cookies de forma predeterminada.</li>
              <li>Safari: Preferencias\Privacidad\Bloquear Cookies.</li>
              <li>Explorer: Herramientas\Opciones de Internet\Privacidad\Configuración\Mover el cursor deslizando totalmente hacia arriba para bloquear todas las cookies o totalmente para abajo para permitir todas las cookies y, a continuación, clicar a “Acceptar”.</li>
            </ul> 
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export default PoliticaCookies;