import React, { Fragment, useState } from "react";
import { Outlet } from "react-router-dom";

import Navigation from "./navigation/header"; 
import Footer from "./navigation/footer";
import RegisterPopup from "./navigation/popups/registerPopup";
import LoginPopup from "./navigation/popups/loginPopup";

function Main() {
	const [openLogin, setOpenLogin] = useState(false);
	const [openRegister, setOpenRegister] = useState(false);

	const handleRegister = (status) => {
		setOpenRegister(status);
	}

	const handleLogin = (status) => {
		setOpenLogin(status);
	}

	return (		
		<Fragment>
			<Navigation handleRegister={handleRegister} handleLogin={handleLogin} />
			<Outlet />
			<RegisterPopup open={openRegister} handleRegister={handleRegister} />			
			<LoginPopup open={openLogin} handleLogin={handleLogin} />		
			<Footer />
		</Fragment>
	);
}

export default Main;

