import React from "react";
import { Box, Typography } from '@mui/material';

import ImagenRaruna from "assets/images/about/imagen_raruna.png";

function NotFound() {   
	return (
    <Box sx={{ width: 1, minHeight: 660, display: 'flex', justifyContent: 'center', bgcolor: 'yoguic.gris.background', mb: 2 }}>
      <Box sx={{ maxWidth: 1440, width: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}> 
        <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', gap: 2 }} >
          <Box sx={{ display: 'flex', justifyContent: '', alignItems: 'center', gap: 2 }}>
            <Typography variant="h1" component="div" fontWeight="bold" sx={{ width: 1, color: 'yoguic.negro.primario' }}>
              Oops!
            </Typography>
            <Box component="img" src={ImagenRaruna} alt="inProgress" sx={{ maxWidth: 145 }} />
          </Box>
          <Typography variant="body1" component="div" sx={{ width: 1, color: 'yoguic.negro.primario' }}>
            No podemos encontrar la página que buscas.
          </Typography>
        </Box>
      </Box>
    </Box>    
	);
}

export default NotFound;