import React, { useState, useEffect } from "react";
import { Box, Typography } from '@mui/material';

import * as Api from "api/course";

import TildeColor from 'assets/images/dashboard/tilde_salmon.png';

import VideoReact from "components/shared/video/videoReact";
import IrACurso from "components/courses/card/irACurso";
import CourseButton from "../courseButton";

function PopupContent(props) {
  const { course } = props;
  const [teaserUrl, setTeaserUrl] = useState(null);

  useEffect(() => {
    Api.GetCourseTeaser(course.name)
    .then(url => setTeaserUrl(url))    
  }, [course]);

  return (
    <Box sx={{width: '90%', display:'flex', flexDirection:'column'}}>
      <Box sx={{ width: 1, height: 480, mb: 2 }}>
        <VideoReact video={teaserUrl} />
      </Box>
      <Box sx={{width: 1, height: 1, display:'flex'}}>
        <Box sx={{ width: '60%', height: 1, display:'flex', flexDirection:'column', gap: 2 }}> 
          <Box component="img" src={TildeColor} alt="tilde_color" sx={{ width: {xs: 20, md:40} }} />
          <Typography variant="body1" component="div" sx={{ maxWidth: 450, fontWeight: 'bold', color: 'yoguic.negro.primario' }}>
            {course.title}
          </Typography>
          <Typography variant="body2" component="div" sx={{ color: "yoguic.negro.primario" }}>
						Un curso de <Typography variant="inherit" display="inline" sx={{ color: "yoguic.rojo.primario" }}>{course.author}</Typography>
					</Typography>
          <IrACurso course={course} />
        </Box>
        <Box sx={{ width:'40%', height: 1, display:'flex', flexDirection:'column', justifyContent: 'center'}}>
          <CourseButton {...props}  />
        </Box>
      </Box>

    </Box>
  );
}

export default PopupContent;