import React, { useState } from "react";
import { Box } from '@mui/material';

import { GetThumbnail } from "utils/imageProvider";

import PlayArrowIcon from '@mui/icons-material/PlayArrow';

function CardImage(props) {
  const { course, onOpen } = props;
  const [hover, setHover] = useState(false);

  const isMentoria = Boolean(course?.category === 'mentoria');

  const overlay = {
    position: 'absolute',
    width: 40,
    height: 40,
    left: '50%',
    top: '50%',
    color: 'white',
    bgcolor: (hover) ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.3)',
    borderRadius: '50%',
    display: (isMentoria) ? 'none' : 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'translate(-50%, -50%)'    
  }

  const thumb = GetThumbnail(course.name);

  return (
    <Box onClick={onOpen} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} sx={{ width: 1, cursor: 'pointer', position: 'relative' }}>
      <Box component="img" src={thumb} alt="course_thumbnail" sx={{ width: 1 }} />
      <Box sx={overlay}>
        <PlayArrowIcon />
      </Box>
    </Box>
  );
}

export default CardImage;