import React, { useState } from "react";
import { Typography, Button, FormControl } from '@mui/material';

import { getError } from "api/apiErrors";
import * as Api from "api/login";

import { RoundedTextInput } from "components/shared/roundTextInput";
import Checkboxes from "./checkboxes";
import PasswordField from "components/shared/passwordField";

function RegisterForm(props) { 
  const { onRegister, email, setEmail } = props;
  const [pass, setPass] = useState("");
  const [policy, setPolicy] = useState(false);  
  const [newsletter, setNewsletter] = useState(false);  
  const [error, setError] = useState('');

  const handleEmailChange = (event) => {
    setError('');
    setEmail(event.target.value)
  }

  const handlePassChange = (event) => {
    let pass = event.target.value
    setPass(pass)
    if (pass.length < 8) {
      setError('La contraseña debe tener al menos 8 caracteres');
    } else {
      setError('');
    }
  }

  const handleRegister = () => {    
    Api.RegisterNewUser(email, pass, newsletter)
    .then(() => onRegister())
    .catch(error => processError(error.cause));
  }

  const processError = (cause) => {
    const error = getError(cause.code);
    setError(error);
  }

  const disableButton = email.length === 0 || pass.length < 8 || !policy || !email.includes(".") || !email.includes("@");

	return (
    <FormControl sx={{ width: "90%", height: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', gap: 3 }}>
        <Typography variant="subtitle1" component="div" fontWeight="bold" sx={{ maxWidth: 400 }}>
          Registrate y comienza a formar parte de nuestra gran comunidad de yoguic
        </Typography>
        <RoundedTextInput variant="outlined" label="Email" onChange={handleEmailChange} sx={{ width: {xs: 250, md: 350} }} />
        <PasswordField setPass={handlePassChange} />      
        {error.length > 0 && <Typography variant="body2" component="div" sx={{ fontWeight: 'bold', color: 'yoguic.rojo.primario' }}>
          {error}
        </Typography>}
        <Checkboxes policy={policy} setPolicy={setPolicy} newsletter={newsletter} setNewsletter={setNewsletter}/>
        <Button variant="contained" onClick={handleRegister} disabled={disableButton} sx={{ height: 53, width: 150, borderRadius: 10, backgroundColor: 'yoguic.negro.primario', mr: 2 }}>
          Crear cuenta
        </Button>      
    </FormControl>
	);
}

export default RegisterForm;