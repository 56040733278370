import React from "react";
import { Box, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";

import CoursePath from "components/shared/coursePath";

function CardText(props) {
  const { course } = props;
  const navigate = useNavigate();

  const coursePath = CoursePath(course);

  return (
    <Box onClick={() => navigate(coursePath)} sx={{ width: 1, display: 'flex', flexDirection: 'column', gap: 2, cursor: 'pointer' }}> 
      <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }} >
        {course.title}
      </Typography>
      <Typography variant="body2" component="div" sx={{ color: "yoguic.negro.primario" }}>
          Un curso de <Typography variant="body2" component="div" sx={{ display: "inline", color: "yoguic.rojo.primario" }}>{course.author || ''}</Typography>
        </Typography>
      <Typography variant="body1" component="div" >
        {course.subtitle}
      </Typography>
    </Box>
  );
}

export default CardText;