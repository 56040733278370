import * as GA from './events';

const loginUrl = "/login"
const uri = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_STACK_NAME + loginUrl;

export const Login = async (email, password) => {
  const response = await fetch(uri, {
		method: 'POST', 
		headers: {'Content-Type': 'application/json'},
		body: JSON.stringify({
			email: email,
			password: password,
		})}
	);
  if (!response.ok) {
		const error = await response.json();
		throw new Error("Error", {cause: error});
  }
  let json = await response.json();
	GA.eventOnLogin();
	return json.token;
}

export const RegisterNewUser = async (email, password, newsletter) => {	
	const response = await fetch(uri + "/register", {
		method: 'POST', 
		headers: {'Content-Type': 'application/json'},
		body: JSON.stringify({
			email: email,
			password: password,
			newsletter: newsletter,
		})
	});
	if (!response.ok) {
		const error = await response.json();
		throw new Error("Error", {cause: error});
	}
	GA.eventOnRegister();
}

export const ValidateMail = async (encodedMail) => {
	const response = await fetch(uri + "/register/" + encodedMail, {method: 'GET'});
	if (!response.ok) {		
		const error = await response.json();
		throw new Error("Error", {cause: error});
	}	
	return await response.json();	
}

export const SendForgottenPassMail = async (email) => {
	const response = await fetch(uri + "/forgotpass/" + email, {method: 'POST'});
	if (!response.ok) {		
		const error = await response.json();
		throw new Error("Error", {cause: error});
	}	
	return;	
}

export const UpdateForgottenPass = async (encodedMail, pass) => {
	const response = await fetch(uri + "/password/" + encodedMail, {
		method: 'POST', 
		headers: {'Content-Type': 'application/json'},
		body: JSON.stringify({
			password: pass,
		})
	});
	if (!response.ok) {		
		const error = await response.json();
		throw new Error("Error", {cause: error});
	}	
	return;	
}
