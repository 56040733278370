import React, { useState } from "react";
import { InputAdornment, IconButton } from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { RoundedTextInput } from "components/shared/roundTextInput";

function PasswordField(props) { 
  const { setPass } = props;
  const [show, setShow] = useState(false);

  const handleClickShowPassword = () => setShow(!show);
  const handleMouseDownPassword = () => setShow(!show);

  return (
    <RoundedTextInput 
      variant="outlined" 
      label="Contraseña" 
      onChange={setPass} 
      type={show ? "text" : "password"}
      sx={{ width: {xs: 250, md: 350} }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
              {show ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
}

export default PasswordField;
  