import React, { useState } from "react";
import { Box, Typography, Button } from '@mui/material';

import { RoundedTextInput } from "components/shared/roundTextInput";
import LoadingBox from "components/shared/loadingBox";
import { SendForgottenPassMail } from "api/login";

function ForgotPassword() {   
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const onClick = () => {
    setLoading(true);
    setError(false);
    SendForgottenPassMail(email)
    .then(() => {
      setLoading(false);
      setSent(true);
    })
    .catch(() => {
      setLoading(false);
      setError(true);
    })
  }

  const handleKey = (event) => {
    if (event.key === 'Enter' && !disableButton) {
      onClick();
    }
  }

  const disableButton = email.length === 0 || !email.includes(".") || !email.includes("@");

  const inputFields = (
    <>
      <RoundedTextInput variant="outlined" label="Email" onChange={handleEmailChange} sx={{ maxWidth: 300 }} />
      <Button variant="contained" onClick={onClick} disabled={disableButton} sx={{ height: 53, width: 150, borderRadius: 10, backgroundColor: 'yoguic.negro.primario', mr: 2 }}>
        Restablecer
      </Button>      
      <Typography variant="body2" component="div" sx={{ color: 'yoguic.rojo.primario' }}>
        {(error) ? "Parece que no hemos encontrado \""+ email +"\" en nuestro sistema, por favor introduce de nuevo tu correo." : ""}
      </Typography>
    </>
  );

  const emailSent = (
    <>
      <Typography variant="body1" component="div">
        Correo de recuperación de contraseña enviado a {email}
      </Typography>
      <Typography variant="body2" component="div">
        Si no ves el correo en tu buzon de entrada pasado unos minutos, revisa tu carpeta de spam.
      </Typography>
    </>    
  );

	return (
    <Box onKeyDown={handleKey} sx={{ width: "90%", height: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', gap: 3 }}>
      <Typography variant="subtitle1" component="div" fontWeight="bold" sx={{ maxWidth: 400 }}>
        Recupera tu contraseña
      </Typography>
      {loading ? <LoadingBox /> : sent ? emailSent : inputFields}
    </Box>
	);
}

export default ForgotPassword;