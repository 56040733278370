import React, { Fragment, useState } from "react";

import LoginForm from "./login/loginForm";
import ForgotPassword from "./login/forgotPassword";

function LoginComponent(props) { 
  const [ forgotPass, setForgotPass ] = useState(false);

  const onForgot = () => {
    setForgotPass(true);
  }

	return (
    <Fragment>
      {forgotPass ? <ForgotPassword /> : <LoginForm onForgot={onForgot} {...props} />}
    </Fragment>  
	);
}

export default LoginComponent;