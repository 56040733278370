import React from "react";
import { Modal, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import PopupContent from "./popupContent";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {xs : 1, md: 870},
  height: 700,
  bgcolor: 'yoguic.gris.background',
  boxShadow: 24,
  borderRadius: 5,  
  display: 'flex',  
  flexDirection: 'column', 
  alignItems: 'center',
};

function CoursePopup(props) {
  const { course, handleClose } = props;
  const open = Boolean(course);

  return (
    <Modal open={open} onClose={(handleClose)}>
      <Box sx={style}>  
        <Box sx={{width: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <PopupContent {...props} />      
      </Box> 
    </Modal >   
  );
}

export default CoursePopup;