import React from "react";
import { Box, Typography } from '@mui/material';

import TildeSalmon from 'assets/images/dashboard/tilde_salmon.png';

function AprendeTexto() {
  const margin = { xs: 0, md: 4 };

	return (
		<Box sx={{ width: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: { xs: 'center', md: 'flex-start'} }} >
			<Box component="img" src={TildeSalmon} alt="tilde" sx={{ width: 60, mb: 2}} />
			<Typography variant="h4" component="h3" sx={{ fontWeight: 'bold', color: 'yoguic.negro.primario', ml: margin, mb: 1 }}>
        Aprende
			</Typography>
			<Typography variant="h4" sx={{ fontStyle: 'italic', color: 'yoguic.negro.primario', ml: margin }}>
        y comparte.
			</Typography>
			<Typography variant="subtitle1" component="div" sx={{ maxWidth: 500, color: 'yoguic.negro.primario', ml: margin, mt: 3, textAlign: { xs: 'center', md: 'left'} }}>
       Trabajamos en colaboración con especialistas, cuyo interés y motivación por compartir su conocimiento es el punto de partida para desarrollar los cursos.       
			</Typography>
      <Typography variant="subtitle1" component="div" sx={{ maxWidth: 500, color: 'yoguic.negro.primario', ml: margin, mt: 3, textAlign: { xs: 'center', md: 'left'} }}>
        El estilo amable y desenfadado de Yoguic, en contraste con la rigurosidad y la seriedad del contenido, hace que los cursos sean placenteros, profundos y fáciles de seguir.
			</Typography>
		</Box>
	);
}

export default AprendeTexto;