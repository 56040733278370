import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import App from './App';

import ScrollToTop from 'utils/scrollToTop';

import { UserProvider } from "providers/userProvider";
import QueryProvider from 'providers/queryProvider';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <UserProvider>
      <QueryProvider>
        <ScrollToTop />
        <App />
      </QueryProvider>
    </UserProvider>
  </BrowserRouter>   
);
