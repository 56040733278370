import React from "react";
import { Box, Typography, Link } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";

import * as GA from 'api/events';

import FooterLogo from 'assets/images/dashboard/footer_yoguic_logo.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

function FooterIcons() {  

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'center', md: 'flex-start' }, gap: 2 }} >
			<Box component="img" src={FooterLogo} alt="footer_logo" sx={{ maxWidth: 130 }} />
			<Typography variant="body1" sx={{ color: 'common.white', fontStyle: 'italic' }}>
				hola@yoguic.com
			</Typography>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
				<Link onClick={()=>GA.eventOnInstagram()} href="https://www.instagram.com/yoguic_/" target="_blank">
    			<InstagramIcon fontSize="large" sx={{ color: 'common.white' }} />
				</Link>
				<Link onClick={()=>GA.eventOnFacebook()} href="https://www.facebook.com/Yoguic" target="_blank" >
    			<FacebookIcon fontSize="large" sx={{ color: 'common.white' }} />
				</Link>
			</Box>			
			<Typography variant="caption" sx={{ color: 'common.white' }}>
				© 2022 Yoguic. All rights reserved. Site by Angry Potato Studio
			</Typography>				
			<Box sx={{ display: 'flex', gap: 1}}>
				<Link variant="caption" component={RouterLink} to="/legal/avisolegal" href="#" target="_blank" sx={{ color: 'common.white' }}>
					Aviso legal
				</Link>
				<Link variant="caption" component={RouterLink} to="/legal/ventas" href="#" target="_blank" sx={{ color: 'common.white' }}>
					Condiciones de venta
				</Link>
				<Link variant="caption" component={RouterLink} to="/legal/cookies" href="#" target="_blank" sx={{ color: 'common.white' }}>
					Política de cookies
				</Link>
				<Link variant="caption" component={RouterLink} to="/legal/privacidad" href="#" target="_blank" sx={{ color: 'common.white' }}>
					Política de privacidad
				</Link>
			</Box>		
		</Box>
	);
}

export default FooterIcons;