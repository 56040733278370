import React from 'react'; 
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const QueryProvider = (props) => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
    </QueryClientProvider>
  );
}

export default QueryProvider;