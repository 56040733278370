import React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from '@mui/material';

import { IsSmall } from 'components/shared/customSizing';

import YogicIcon from 'assets/images/navigation/logo_cabecera.png';
import YogicMiniIcon from 'assets/images/navigation/mini_logo_cabecera.png';


function HeaderLogo() {
  let navigate = useNavigate();

	return IsSmall() ?
     <Box onClick={() => navigate('/')} component="img" src={YogicMiniIcon} alt="yoguic" sx={{ width: 42, mx: 2 }} /> :
     <Box onClick={() => navigate('/')} component="img" src={YogicIcon} alt="yoguic" sx={{ width: 120, mx: 1 }} />
}

export default HeaderLogo;