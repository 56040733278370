import React from "react";
import { Box, Typography } from '@mui/material';

import { IsSmall } from "components/shared/customSizing";

import Carousel from "components/courses/list/carousel";
import CarouselLarge from "components/courses/list/carouselLarge";

import TildeColor from 'assets/images/dashboard/tilde_salmon.png';

function CourseSection() {  

	return (		
    <Box sx={{ height: { xs: 1200, md: 950 }, width: 1, display: 'flex', justifyContent: 'center', bgcolor: 'yoguic.gris.background' }}>
      <Box sx={{ width: 1, maxWidth: 1440, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ height: '95%', width: '95%', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 3}}>
          <Box component="img" src={TildeColor} alt="tilde_color" sx={{ width: {xs: 40, md:70} }} />
          <Typography variant="h4" component="h4" sx={{ color: 'yoguic.negro.primario', fontWeight:'bold', textAlign: { xs: 'center', md: 'left'} }}>
            Cursos online
          </Typography>
          <Typography variant="body1" component="div" sx={{ color: 'yoguic.negro.primario', textAlign: { xs: 'center', md: 'left'} }}>
            Nuestros cursos online <b>están siempre disponibles</b> para ver a tu ritmo, con tus propios horarios, y desde donde estés. Los cursos están dirigidos a <b>profesorxs de yoga, practicantes</b> y/o personas interesadas en el <b>desarrollo personal.</b>
          </Typography>
          {IsSmall() ? <Carousel category={"curso"} /> : <CarouselLarge category={"curso"} />}
        </Box>
      </Box> 
    </Box>  
	);
}

export default CourseSection;
