import React from "react";
import { AppBar, Box } from '@mui/material';

import HeaderLogo from "./header/headerLogo";
import HeaderNav from "./header/headerNav";
import HeaderUser from "./header/headerUser";

import { IsSmall } from "components/shared/customSizing";

function Header(props) {
  const { handleRegister, handleLogin } = props;

  const position = IsSmall() ? "sticky" : "static";

	return (
    <AppBar position={position} sx={{ background: '#FFFFFF', height: 85, width: 1 }}>
      <Box sx={{ height: 1, display:'flex', alignItems: 'center' }}>  
        <HeaderLogo />        
        <HeaderNav />
        <Box sx={{ flexGrow: 1 }} />
        <HeaderUser handleRegister={handleRegister} handleLogin={handleLogin} />
      </Box>
    </AppBar> 
	);
}

export default Header;