import { createTheme, responsiveFontSizes } from '@mui/material/styles';

/*
  typography: {
    fontFamily: [
      'Poppins',
      'Roboto'
    ].join(','),
  },
*/

let theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    background: {
      default: "#0D6851"
    },
    primary: {
      main: "#1E1E1C",
      contrastText: "#FFFFFF"
    },
    yoguic: {      
      verde: {
        primario: '#0D6851',
        secundario: '#46A48C',
        terciario: '#CFE7E0'
      },
      rojo: {
        primario: '#DC5C57',
        secundario: '#F19698',
        terciario: '#F8D0D2'
      },
      gris: {
        flojo: '#EEEEEE',
        medio: '#BDBDBD',
        fuerte: '#848484',
        background: '#F5F5F5',
      },
      amarillo: {
        primario: '#EAB62A'
      },
      negro: {
        primario: '#1E1E1C'
      },
      blanco: {
        primario: '#FFFFFF'
      }
    }    
  }  
});

theme = responsiveFontSizes(theme);

export default theme