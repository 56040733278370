import React from "react";
import { Modal, Box } from '@mui/material';

import yoguicLogo from "assets/images/navigation/logo_cabecera.png";
import RegisterComponent from "components/shared/account/registerComponent";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 530,
  height: 600,
  bgcolor: 'yoguic.gris.background',
  boxShadow: 24,
  borderRadius: 5,  
  display: 'flex',  
  flexDirection: 'column', 
  alignItems: 'center'
};

function RegisterPopup(props) { 
  const { open, handleRegister } = props;

  const handleClose = () => {
    handleRegister(false);
  }

	return (
    <Modal open={open} onClose={(handleClose)}>
      <Box sx={style}>
        <Box sx={{width: 1,  height: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <RegisterComponent onClose={handleClose} />
          <Box sx={{display: 'flex', justifyContent: 'center', mb: 4}}>
            <Box component="img" src={yoguicLogo} sx={{ maxWidth: 150 }} />
          </Box>
        </Box>
      </Box> 
    </Modal >   
	);
}

export default RegisterPopup;