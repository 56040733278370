export const eventOnLogin = () => eventHandler("login");
export const eventOnRegister = () => eventHandler("sign_up");
export const eventOnAddToCart = () => eventHandler("add_to_cart");
export const eventOnPurchase = () => eventHandler("purchase");
export const eventOnInstagram = () => customEventHandler("Instagram", "Navigation");
export const eventOnFacebook = () => customEventHandler("Facebook", "Navigation");
export const eventOnNewsletter = () => customEventHandler("Newsletter", "Navigation");

const eventHandler = (eventName) => {
  window.gtag('event', eventName);
}

const customEventHandler = (action, category) => {
  window.gtag('event', action, {"event_category": category});
}
