import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button } from '@mui/material';

import { flexHideOnSmall } from '../../shared/customSizing';

function HeaderNav() {
  let navigate = useNavigate();

	return (
    <Box sx={{ display: flexHideOnSmall, gap: 2 }}>
      <Button onClick={() => navigate('/courses')} variant="text" sx={{ fontSize: 14, color: 'yoguic.negro.primario' }}>
        Cursos
      </Button> 
      <Button onClick={() => navigate('/programs')} variant="text" sx={{ fontSize: 14, color: 'yoguic.negro.primario' }}>
        Programas
      </Button> 
      <Button onClick={() => navigate('/conocenos')} variant="text" sx={{ fontSize: 14, color: 'yoguic.negro.primario' }}>
        Conócenos
      </Button> 
      <Button onClick={() => navigate('/contacto')} variant="text" sx={{ fontSize: 14, color: 'yoguic.negro.primario' }}>
        Contacto
      </Button>
    </Box> 
	);
}

export default HeaderNav;