import * as GA from './events';

const userUrl = "/user";
const uri = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_STACK_NAME + userUrl;

export const RegisterEmailNewsletter = (email) => {	
	GA.eventOnNewsletter();
	fetch(uri + "/newsletter/" + email, {method: 'POST'})		
		.catch(error => {return error})
}

export const GetUserContext = async (token) => {	
  const response = await fetch(uri, {method: 'GET', headers: {'Authorization': 'Bearer ' + token}});
  if (!response.ok) {
    throw new Error(response.status);
  }
  return await response.json();
}

/*export const GetDeviceListLocal = (callback) => {
	const data = [
		{	id: '1', type: 'cobas Liat', name: 'Liat in Minilab 1', version: '3.3', lastConnection: Date.now(), connected: false, status: '' },
		{	id: '2', type: 'CoaguCheck Pro II', name: 'Coag in Minilab 1', version: '1.2', lastConnection: Date.now(), connected: true, status: '' },		
	]
	callback(data);
}

export const GetDeviceList = (callback)=>{	
	fetch(uri, {method: 'GET', headers : {'authorizationToken': 'Bearer ' + process.env.REACT_APP_TOKEN}})
	.then(res => res.json())
	.then((data) => { callback(data.devices); })
	.catch(function (error) {
		console.log(error);
	});
}

export const RegisterDevice = (device)=>{	
	return fetch(uri, 
		{	method: 'PUT', 
			headers : {	'authorizationToken': 'Bearer ' + process.env.REACT_APP_TOKEN, 
								'Content-Type': 'application/json',
								'Access-Control-Allow-Origin':'*'}, 
			body: JSON.stringify(device)
		}
	);	
}*/
