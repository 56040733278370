// MediaQuery
import { useMediaQuery, useTheme } from '@mui/material';

export const IsSmall = () => {
  const theme = useTheme();  
  return useMediaQuery(theme.breakpoints.down('lg'));
}

// Display
export const hideOnSmall = { xs: 'none', md: 'block' }
export const hideOnBig = { xs: 'block', md: 'none' }
export const flexHideOnSmall = { xs: 'none', md: 'flex' }

// Width
export const halfWidthOnBig = { xs: 1, md: 1/2 }

// Items Align
export const centerItemsOnSmall = { xs: 'center', md: 'flex-start' }

// Text Align
export const centerTextOnSmall = { xs: 'center', md: 'left'}

// Flex Direction
export const columnOnSmall = {xs: 'column', md: 'row'}

// Justify center on small
export const justifyCenter = {xs: 'center', md: 'space-between'}

// Margins
export const MarginCalc = (m) => {
  if (IsSmall()) {
    return 0;
  } else {
    return m;
  }
}