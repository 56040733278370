import React from "react";
import { Box, Divider } from '@mui/material';
import { List, ListItem, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';

import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined';

function DrawerList(props) {
  const { onClick, user } = props;

  const onDisconect = () => {
    user.logout();
    onClick("/");
  };

  const ifLogged = (      
    <>
      <ListItem disablePadding>
        <ListItemButton onClick={() => onClick("/cart")} >
          <ListItemIcon>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText primary={"Carrito"} />
        </ListItemButton>
      </ListItem>  
      <ListItem disablePadding>
        <ListItemButton onClick={onDisconect} >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={"Desconectar"} />
        </ListItemButton>
      </ListItem>
    </>
  );

  const ifNotLogged = (        
    <ListItem disablePadding>
      <ListItemButton onClick={() => onClick("/register")} >
        <ListItemIcon>
          <LoginIcon />
        </ListItemIcon>
        <ListItemText primary={"Conéctate"} />
      </ListItemButton>
    </ListItem>
  );

	return (
    <Box sx={{ bgcolor: 'background.paper' }}>
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => onClick("/courses")} >
            <ListItemText primary={"Cursos"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => onClick("/programs")} >
            <ListItemText primary={"Programas"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => onClick("/conocenos")} >
            <ListItemText primary={"Conócenos"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => onClick("/contacto")} >
            <ListItemText primary={"Contacto"} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        {user.isLogged() ? ifLogged : ifNotLogged}
      </List>
    </Box>
  );
}

export default DrawerList;