import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Typography, Switch } from '@mui/material';

import ReactPlayer from 'react-player/lazy'

const high = "¿Problemas con la reproducción? Cambia a calidad baja";
const low = "Calidad Baja";

function VideoReact(props) {
  const { video } = props;
  const [url, setUrl] = useState('');
  const [hd, setHd] = useState(true);
  const [label, setLabel] = useState(high);

  useEffect(() => {
    if (video) {
      setUrl(hd ? video.highHDUrl : video.lowHDUrl);
    } else {
      setUrl('');
    }
  }, [hd, video]);

  const handleChange = (event) => {
    setHd(event.target.checked);
    setLabel(event.target.checked ? high : low);
  };

  const loading = (
    <Box sx={{ width: 1, height: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'yoguic.rojo.primario'}}>
      <CircularProgress />
    </Box>     
  );

  const loadVideo = (
    <Box sx={{width: 1, height: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 1}}>
      <ReactPlayer url={url} controls={true}  config={{ file: { attributes: { controlsList: 'nodownload' } } }} width='100%' height='100%' />
      <Box sx={{ width: 1, height: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} >
        <Typography variant="caption" component="div">{label}</Typography>
        <Switch checked={hd} onChange={handleChange} size="small" inputProps={{ 'aria-label': 'cuality' }} />
      </Box>
    </Box>
  );

	return (
    <>
      {(video) ? loadVideo : loading}
    </>
	);
}

export default VideoReact;
